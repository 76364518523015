
<b-row class="mt-4 product-tile" no-gutters align-v="center" align-h="center">
  <b-col cols="4" class="m-auto text-center pb-4">
    <b-img
      v-if="value.image"
      class="product-image shadow"
      :alt="value.title"
      :src="value.image"
      :title="value.title"
      fluid
      lazy
      width="160"
      height="160"
      @click="viewing = value"
      @error="value.image = null"
    />
    <div v-else class="image-placeholder product-placeholder">
      <div>{{ imagePlaceholder(value.title) }}</div>
    </div>
  </b-col>

  <b-col cols="12" class="product-tile__title">{{ value.title }}</b-col>

  <b-col v-show="showActions" cols="12">
    <b-form-text
      v-if="region.governance.EnableFeatureInventory"
      id="inputLiveHelp"
      class="product-quantity-feedback"
    >
      {{ stock }} in stock
    </b-form-text>
    <b-form-text
      v-else-if="isSmartWeight && !region.governance.EnableFeatureSmartWeight"
      id="inputLiveHelp"
      class="product-quantity-feedback"
    >
      SmartWeight&trade; products aren't available in the
      {{ region.name }} region yet. Check back soon.
    </b-form-text>

    <div v-if="region.governance.EnableFeatureModifiers" class="my-3">
      <product-modifiers
        v-model="value.modifiers"
        :validated.sync="modifiersValid"
      />
    </div>

    <b-row v-if="isRegular" align-v="center" align-h="center" no-gutters>
      <b-col cols="12" class="pb-2">
        <b-button-group class="cart-products-btn w-100">
          <b-button class="btn col-4" @click="removeFromCart(value)"
            >Remove</b-button
          >
          <b-button
            v-if="!value.max_weekly_frequency && !value.subscription"
            class="btn-outline"
            @click="subtractProductQty(value)"
          >
            <b-icon-dash></b-icon-dash>
          </b-button>
          <b-button
            v-if="!value.max_weekly_frequency && !value.subscription"
            class="btn-outline"
            @click="addProductQty(value)"
          >
            <b-icon-plus></b-icon-plus>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <template v-else-if="region.governance.EnableFeatureSmartWeight">
      <smart-weight-controls
        v-if="isSmartWeight"
        v-model="value"
        :removable="true"
        :trigger-update="true"
      />
      <fixed-weight-controls
        v-if="isFixedWeight"
        v-model="value"
        :removable="true"
        :trigger-update="true"
      />
    </template>

    <b-row
      align-v="center"
      align-h="center"
      no-gutters
      class="justify-content-between pt-1"
    >
      <b-col cols="auto">
        <span v-if="isRegular"
          >{{ value.quantityOrdered }} x
          <Currency
            primary-font-size="1em"
            secondary-font-size=".8em"
            prepend=""
            :currency="unitPrice"
          />
        </span>
      </b-col>
      <b-col cols="auto" class="font-weight-bold">
        <Currency
          primary-font-size="1rem"
          secondary-font-size=".8em"
          prepend="$"
          :currency="completePrice"
        />
      </b-col>
    </b-row>
  </b-col>

  <product-info v-if="viewing" v-model="viewing" :view-reviews="reviewing" />
  <product-reviews v-if="reviewing" v-model="reviewing" />
</b-row>
