
<div class="product-card">
  <div
    class="product-img-wrapper"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      class="product-img-wrapper__additional-info"
      style="top: 15px !important"
    >
      <b-badge
        v-if="value.product.rating"
        variant="light"
        @click.stop="viewReviews($event, value)"
      >
        {{ value.product.rating }}
        <b-icon icon="star-fill" color="gold"></b-icon>
      </b-badge>

      <b-badge
        variant="light"
        v-if="value && value.product.default_calories > 0"
      >
        <span>{{ value.product.default_calories }} cal</span>
      </b-badge>
    </div>

    <div v-if="showAvailabilityText" class="product-card__cut-off">
      {{ availabilityText }}
    </div>

    <b-badge
      v-if="rotatingSubscription.use_product_prices || rotatingSubscription.pricing_model === 'PER_MEAL'"
      variant="dark"
      class="product-img-wrapper__price"
      style="top: 15px !important"
    >
      <Currency
        prepend="$"
        :currency="value.price"
        :secondary-font-size="'0.825em'"
      />
    </b-badge>

    <b-img
      v-if="value.product.image"
      class="product-img"
      :alt="value.product.title"
      :src="value.product.image"
      :title="value.product.title"
      fluid
      lazy
      @error="value.product.image = null"
    />

    <div v-else class="image-placeholder">
      <div>{{ imagePlaceholder(value.product.title) }}</div>
    </div>

    <div class="product-card__tags">
      <Tag v-for="tag in value.product.tags" :key="tag.uuid" v-b-popover.hover.top="tag.name" :value="tag" size="sm"
           :v-b-popover="tag.name"/>
    </div>
  </div>

  <div class="product-card__title truncate">
    {{ value.product.title }}
  </div>

  <div
    @mouseover="hover = true"
    @mouseleave="hover = false"
    style="margin-top: auto; display: flex"
  >
    <b-button
      :disabled="outOfStock || !canEdit"
      block
      class="product-card__submit rotating-card__submit"
      @click="$emit('add-to-subscription', value)"
    >
      <span>Select</span>

      <div v-if="value.product.quantity" class="badge badge-danger rotating-card__meals-count">
        <span class="rotating-card__meals-count_text">
          {{ value.product.quantity }}
        </span>
      </div>
    </b-button>

    <b-button
      v-b-popover.hover.top="
        isFavorite ? 'Remove Favorite' : 'Mark as Favorite'
      "
      :v-b-popover="isFavorite ? 'Remove Favorite' : 'Mark as Favorite'"
      @click="toggleFavorite"
      :disabled="outOfStock || !canEdit"
      block
      class="product-card__submit rotating-card__favorite"
    >
      <div class="product-favorite__tags">
        <b-icon
          :icon="isFavorite ? 'heart-fill' : 'heart'"
          style="color: #cd0205"
        />
      </div>
    </b-button>
  </div>
</div>
