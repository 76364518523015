<template>
  <div>
    <b-alert v-if="value.error" class="alert-toast mb-5" variant="warning" show>
      {{ value.error }}
    </b-alert>
    <b-row class="mt-3 mx-auto">
      <b-col> Subtotal </b-col>
      <b-col class="text-right">
        <number :to="value.subtotal" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <b-row v-if="parseFloat(value.discount) > 0" class="mx-auto text-muted">
      <b-col>
        Discount
        <a href="#" @click.prevent="showPromoInfo = true">
          <b-icon-question-circle-fill variant="primary" />
        </a>
      </b-col>
      <b-col class="text-right">
        <span class="discount-value">-
          <number :to="value.discount" :duration="0.12" :format="formatDefaultPrice" />
        </span>
      </b-col>
    </b-row>
    <b-row v-if="parseFloat(value.fees) > 0 && parseFloat(value.courier_tax) > 0 && parseFloat(value.vendor_tax) > 0 && parseFloat(value.fees) == parseFloat(value.roadie_fees)" class="mx-auto">
      <b-col> Tax </b-col>
      <b-col class="text-right">
        <number :to="value.vendor_tax" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <b-row v-else-if="parseFloat(value.tax) > 0" class="mx-auto">
      <b-col> Tax </b-col>
      <b-col class="text-right">
        <number :to="value.tax" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <b-row v-if="parseFloat(value.gift_card_credit) > 0" class="mx-auto text-muted">
      <b-col>Gift Card</b-col>
      <b-col class="text-right">
        -<number :to="value.gift_card_credit" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <b-row v-if="parseFloat(value.shipping_fees) > 0" class="mx-auto">
      <b-col> Shipping Fee </b-col>
      <b-col class="text-right">
        <number :to="value.shipping_fees" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <b-row v-if="parseFloat(value.fees) > 0 && parseFloat(value.fees) != parseFloat(value.roadie_fees)" class="mx-auto">
      <b-col> Delivery Fee </b-col>
      <b-col class="text-right">
        <number :to="value.fees" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <b-row v-if="parseFloat(value.tip) > 0" class="mx-auto">
      <b-col>
        Tip
        <a v-if="
          value.promotion &&
          value.promotion.min_tip &&
          !(parseFloat(value.promotion.min_tip) < parseFloat(value.tip))
        " href="#" @click.prevent="showPromoInfo = true">
          <b-icon-question-circle-fill variant="primary" />
        </a>
      </b-col>
      <b-col class="text-right">
        <number :to="value.tip" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <hr>
    <b-row 
      v-if="isValidNumber(value.fees) && isValidNumber(value.roadie_fees) && isValidNumber(value.courier_tax) && parseFloat(value.fees) > 0 && parseFloat(value.courier_tax) > 0 && parseFloat(value.fees) == parseFloat(value.roadie_fees)" 
      class="mx-auto">
      <b-col> 3rd Party Delivery Fee </b-col>
      <b-col class="text-right">
        <number :to="(Number(value.fees) + Number(value.courier_tax))" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <b-row class="grand-total pt-1 pb-2 mx-auto">
      <b-col> Total </b-col>
      <b-col class="text-right font-weight-bold">
        <number :to="value.total" :duration="0.12" :format="formatDefaultPrice" />
      </b-col>
    </b-row>
    <discount-code-info v-model="showPromoInfo" :promotion="value.promotion" />
  </div>
</template>

<script>
import { formatDefaultPrice } from '%/formatPrice';
import DiscountCodeInfo from '@/cart/partials/DiscountCodeInfo.vue';
import Currency from '@/Currency.vue';

export default {
  name: 'CostBreakdown',
  components: { Currency, DiscountCodeInfo },
  props: ['value'],
  data() {
    return {
      showPromoInfo: false,
    };
  },
  methods: {
    formatDefaultPrice,
    isValidNumber(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.divider {
  border-top-color: var(--primary) !important;
  border-top: solid 1px;
}

.totals-row {
  font-family: $lato-font;
  font-weight: 700;
}

.grand-total {
  font-size: 1.5rem;
}

.discount-value span {
  display: table-cell;
}
</style>
