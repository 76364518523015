
<b-col cols="24">
  <ProductGrid
      key="products"
      :products="selectedProducts"
      :loading="loading"
      :rotating-subscription="model"
      :meals-selected="mealsSelected"
      :can-edit="false"
  ></ProductGrid>

  <b-col cols="12">
    <b-row
        class="justify-content-between w-100 m-0 macros-table"
        align-h="center"
        align-v="center"
    >
    </b-row>
    <b-row class="w-100 m-0 product-order-footer" align-v="center">
      <b-col class="px-0" cols="6">
        <b-btn
            class="float-right footer-btn footer-btn-add"
            block
            @click="clearCart"
        >
          <span class="small">Remove </span>
        </b-btn>
      </b-col>
      <b-col class="px-0" cols="6">
        <b-btn
            class="float-right footer-btn footer-btn-add"
            :disabled="!rotatingSubscriptionOrderValid"
            block
            @click="updatedRotatingSubscription = value"
        >
          <span class="small">Modify </span>
        </b-btn>
      </b-col>
    </b-row>
  </b-col>

  <rotating-subscription-order-modal
      v-if="updatedRotatingSubscription"
      :value="updatedRotatingSubscription"
      :vendor_uuid="vendor.uuid"
      :currently-selected-products="selectedProducts"
      @hidden="updatedRotatingSubscription = null"
  />
</b-col>
