<template>
  <b-table-simple small responsive fixed class="text-center mb-1 product-macros-table">
    <b-thead class="product-macros-table__header">
      <b-tr style="background: var(--primary); color: white; width: 100%;">
        <b-th colspan="14">
          Delivery Schedule
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody class="product-macros-table__body">
      <b-tr>
        <b-th colspan="2">Sun</b-th>
        <b-th colspan="2">Mon</b-th>
        <b-th colspan="2">Tue</b-th>
        <b-th colspan="2">Wed</b-th>
        <b-th colspan="2">Thu</b-th>
        <b-th colspan="2">Fri</b-th>
        <b-th colspan="2">Sat</b-th>
      </b-tr>

      <b-tr>
        <b-td colspan="2" v-for="day of daysOfWeek" :key="day">{{ formatTime(subscription[day]) }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import moment from "moment";

export default {
  name: 'Schedule',
  props: ['subscription', 'daysOfWeek'],
  methods: {
    formatTime(time24) {
      return time24 ? moment(time24, "HH:mm:ss").format("h:mm A") : ' - ';
    },
  }
}
</script>