
<div class="" v-if="macros.length || macros.calories">
  <div
    class="content-wrapper"
    v-if="macros.length || macros.calories"
    :class="{
      tabContentWrapper: !changeTab,
    }"
  >
    <div class="wrapper mt-5">
      <h3 class="header">Edit Your Macros</h3>
      <div class="form macrosForm">
        <b-form-group label="Calories">
          <b-form-input
            v-model="macros.calories"
            :rules="[rules.required]"
            label="Calories"
            class="mr-2"
          />
        </b-form-group>
        <b-form-group label="Proteins (Grams)">
          <b-form-input
            v-model="macros.protein"
            :rules="[rules.required]"
            label="Protein"
            class="mr-2"
          />
        </b-form-group>
        <b-form-group label="Carbohydrates (Grams)">
          <b-form-input
            v-model="macros.carbs"
            :rules="[rules.required]"
            label="Carbs"
            class="mr-2"
          />
        </b-form-group>
        <b-form-group label="Fats (Grams)">
          <b-form-input
            v-model="macros.fat"
            :rules="[rules.required]"
            label="Fat"
            class="mr-2"
          />
        </b-form-group>
      </div>
    </div>
    <b-row align-h="center">
      <my-button :on-click="saveUserMacros" center :disbled="loading">
        Save
      </my-button>
    </b-row>
  </div>
</div>
