<template>
  <b-row v-if="loading" id="container" align-h="center" align-v="center">
    <b-col class="mx-auto">
      <b-img :src="loadingGif" class="w-100" fluid></b-img>
    </b-col>
  </b-row>
  <b-modal
    v-else
    v-model="active"
    title-class="h3"
    body-class="product-order-modal-content-container"
    modal-class="product-order-modal-full-height"
    no-close-on-backdrop
    lazy
    @hidden="$emit('hidden', null)"
  >
    <template #modal-title>
      <b-col class="p-0">
        <p class="h3">{{ model.title }}</p>
        <b-row v-if="!$store.getters.vendor" class="ml-0" align-v="center">
          <b-img
            v-if="vendor.logo_image"
            :alt="vendor.name"
            :src="vendor.logo_image"
            :title="vendor.name"
            rounded
            lazy
            class="vendor-logo"
            @error="vendor.logo_image = null"
          />
          <p class="h5 ml-2 mb-0">{{ vendor.name }}</p>
        </b-row>
      </b-col>
    </template>
    <b-row
      class="justify-content-between mb-3"
      align-h="center"
      align-v="center"
    >
      <b-col cols="auto">
        <b-form-rating
          v-model="model.rating"
          class="pl-0"
          color="gold"
          inline
          no-border
          readonly
          show-value
          @click.prevent="viewingRatings = cloneDeep(model)"
        />
      </b-col>
      <b-row class="mr-2">
        <b-col cols="auto">
          <h3>
            <a
              href="#"
              class="text-right text-reviews"
              @click.prevent="viewingRatings = cloneDeep(model)"
              >{{ model.ratings_count }} Reviews</a
            >
          </h3>
        </b-col>
      </b-row>
    </b-row>

    <b-row align-v="center">
      <b-col md="auto" cols="6">
        <b-img
          v-if="model.image"
          class="product-image shadow"
          :alt="model.title"
          :src="model.image"
          :title="model.title"
          fluid
          lazy
          @error="model.image = null"
        />
        <div v-else class="image-placeholder">
          <div>{{ imagePlaceholder(value.title) }}</div>
        </div>
      </b-col>
      <b-col>
        <b-row wrap>
          <b-col
            v-for="tag in model.tags"
            :key="tag.name"
            md="6"
            sm="12"
            xs="12"
            class="mb-2"
          >
            <Tag
              size="sm"
              :value="tag"
              class="d-inline-flex"
            />&nbsp;
            <span class="text-muted small">{{ tag.name }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row v-if="model.description" align-h="center">
      <b-col cols="12" class="order-product-description pb-2">
        <h3 class="mt-4">Description</h3>
        <div class="small" v-html="model.description"></div>
      </b-col>
    </b-row>

    <b-row v-if="model.show_ingredients && getIngredientsNames.length" align-h="center">
      <b-col cols="12">
        <h3 class="mt-4">Ingredients</h3>
        <p class="small">
          {{getIngredientsNames}}
        </p>
      </b-col>
    </b-row>

    <hr v-if="model.modifiers" class="mt-2" />

    <b-row class='no-scroll'>
      <template>
        <product-modifiers
          v-if="model.modifiers"
          v-model="model.modifiers"
          :validated.sync="modifiersValid"
          :is-mods-disabled = "isMaxSelected"
          expanded
          @model-changed="handleModelChanged"
        />
      </template>
    </b-row>

    <template #modal-footer>
      <b-row
        class="justify-content-between w-100 m-0 macros-table"
        align-h="center"
        align-v="center"
      >
        <div v-if="modsValidationString" :class="['p-1 text-center mx-auto', isMaxSelected ? 'max-text' : 'error-text']">
          {{ modsValidationString }}
        </div>
        <ProductMacrosTable :macros="macros" :nutritions="nutritionRecommendation" :isGoalCenter="vendor.settings.enable_goal_center" />
      </b-row>
      <b-row class="w-100 m-0 product-order-footer" align-v="center">
        <b-col
          v-if="!model.max_weekly_frequency"
          cols="4"
          class="px-0 d-flex justify-between align-items-center"
        >
          <b-btn-group class="w-100">
            <b-btn
              class="footer-btn btn"
              :disabled="decrementIsDisabled"
              @click="decrementQuantity"
            >
              <b-icon-dash scale="1.5" />
            </b-btn>
            <b-btn
                class="footer-btn btn"
                :disabled="incrementIsDisabled"
                @click="incrementQuantity"
            >
              <b-icon-plus scale="1.5" />
            </b-btn>
          </b-btn-group>
        </b-col>
        <b-col class="px-0">
          <b-btn
            class="float-right footer-btn footer-btn-add"
            :disabled="!productOrderValid"
            block
            @click="addToCart()" 
          >
          <template v-if="productOrderValid">
            <span class="small">Add</span>
               {{ model.quantity }}
            <span class="small"
              >Item{{ model.quantity > 1 ? 's' : '' }}
            </span>
            <span class="small">To Order </span>
            <span class="mdi mdi-cart cart-icon" />
            <span v-if="rotatingSubscription.use_product_prices || rotatingSubscription.pricing_model === 'PER_MEAL' && completePrice" class="product-order-footer__price">
              &ndash;
              <span>$</span>
              <number
                :to="completePrice"
                :duration="0.12"
                :format="formatPriceToCompact"
              />
            </span>
          </template>
          <template v-else>
            Customize To Proceed
          </template>
          </b-btn>
        </b-col>
      </b-row>
    </template>
    <product-reviews v-if="viewingRatings" v-model="viewingRatings" />
  </b-modal>
</template>

<script>
import loadingGif from '~/../assets/loading.gif'
import ProductModifiers from '@/shop/product-partials/ProductModifiers.vue';
import ProductReviews from '@/shop/product-partials/ProductReviews.vue';
import { cloneDeep } from 'lodash';
import { mapGetters } from 'vuex';
import Tag from '^/components/Tag.vue';
import { axios } from '^/axios';
import { configuration } from '~/configuration';

import {
  getProductMacros,
  handleSmartWeightConversion,
  imagePlaceholder,
} from '^/utilities';
import { formatPriceToCompact } from '%/formatPrice';
import ProductMacrosTable from '@/Macronutrients/Macros/ProductMacrosTable.vue';

export default {
  name: 'ProductOrderModal',
  props: ['value', 'nutritionRecommendation','vendor_uuid', 'rotatingSubscription', 'mealsSelected'],
  data() {
    return {
      loading: false,
      active: false,
      model: {
        title: String(),
        brand: null,
        quantity: 1,
        modifiers: [],
        image: String(),
        ratings_count: 0,
        ingredients: [],
        recipes: []
      },
      modifiersValid: [],

      viewingRatings: null,
      selectedQty: 0,
      cloneDeep,
      productMacros: getProductMacros(this.value.product),
      configuration,
      loadingGif
    };
  },
  watch: {
    value(val) {
      this.active = val != null;
    },

    active(val) {
      if (val)
        this.selectedQty = this.mealsSelected

        if (this.model.quantity < 1 && !this.incrementIsDisabled) {
          this.incrementQuantity()
        }
    },

    nutritionRecommendation() {
      return {
        handler(nutritionRecommendation) {
          return nutritionRecommendation;
        },
        deep: true,
      };
    },
  },
  async created() {
    this.loading = true;
    this.value.product.modifiers ||= []
    this.value.product.ingredients ||= []
    this.value.product.recipes ||= []
    this.model = cloneDeep(this.value.product);
    this.model.modifiers = await this.getModifiers()
    this.model.ingredients = await this.getIngredients()
    this.model.recipes = await this.getRecipes()
    this.$forceUpdate()
    this.loading = false;
    this.active = true;
  },
  methods: {
    getProductMacros,
    imagePlaceholder,
    formatPriceToCompact,
    formatToMacro(value) {
      return Math.max(value).toFixed(0);
    },
    formatToPrice(value) {
      return Math.max(value).toFixed(2);
    },
    decrementQuantity() {
      this.$set(this.model, "quantity", this.model.quantity - 1);
      this.value.quantity = this.model.quantity;
      this.selectedQty--;
    },
    incrementQuantity() {
      this.$set(this.model, "quantity", this.model.quantity + 1);
      this.value.quantity = this.model.quantity;
      this.selectedQty++;
    },
    async getRecipes() {
      return await axios.get(`/product/${this.model.uuid}/recipes`);
    },
    async getIngredients() {
      return await axios.get(`/product/${this.model.uuid}/ingredients`);
    },
    async getModifiers() {
      let modifiers = await axios.get(`/product/${this.model.uuid}/modifiers`);

      return modifiers.map(mod => {
        const m = this.value.product.modifiers.find(mod => mod.uuid === mod.uuid);
        if (m) {
          mod.items = mod.items.map(item => {
            const modItem = m.items.find(mItem => mItem.uuid === item.uuid);
            if (modItem) {
              item.item_quantity = modItem.item_quantity;
            }

            return item;
          });
        }

        return mod;
      });
    },
    handleModelChanged() {
      this.productMacros = getProductMacros(this.model);
    },

    addToCart() {
      this.value.product = cloneDeep(this.model)
      this.active = false;
      this.$emit('add-product-to-subscription', this.value)
      this.$emit('hidden', null);
    },
    formatGrams(number) {
      return this.formatToMacro(number) + 'g';
    },
    
    getRecipesIngredients(recipes, input = []) {
      if(!recipes || !recipes.length) return input;

      let output = input;

      recipes.forEach(item => {
        let recipesIngredients = item.ingredients.map(ing => ing.ingredient.description);
        output = [...output, ...recipesIngredients];

        if (item.all_related_recipes && item.all_related_recipes.length > 0) {
          output = this.getRecipesIngredients(item.all_related_recipes, output);
        }
      });;
      return output;
    }
  },
  computed: {
    ...mapGetters(['region', 'cartProducts', 'vendor']),
    remainingSlots() {
      return this.rotatingSubscription.max_items - (this.selectedQty || 0);
    },

    macros() {
      const macros = getProductMacros(this.model);

      Object.keys(macros).forEach((key) => {
        macros[key] = macros[key] * this.model.quantity;
      })

      return macros;
    },

    completePrice() {
      let price = 0.0;

      if (this.rotatingSubscription.use_product_prices) {
        if (this.model.fixed_weight_enabled) {
          // Find matching option
          let opt = this.model.fixed_weight_prices.find(
            (o) =>
              o.amount === this.model.quantity &&
              o.weight === this.model.orderedWeight
          );
          if (opt) price = opt.price;
        } else if (this.model.smart_weight_enabled) {
          price = handleSmartWeightConversion(
              this.model.quantity,
              this.model.orderedWeight,
              this.model.sold_by_weight
            ) * this.model.price;
        } else {
          price = this.model.quantity * this.model.price;
        }
      } else {
        if (this.rotatingSubscription.pricing_model === "PER_MEAL") {
          price = this.rotatingSubscription.price_override * this.model.quantity;
        }
      }

      if (this.model.modifiers) {
        price =
          price +
          this.model.modifiers
            .map((x) =>
              x.items.reduce(
                (a, item) => {
                  if(item.is_floor_price)
                    return a += Math.max(item.item_quantity, item.default_quantity) * item.price_adjustment;

                  return a += item.item_quantity * item.price_adjustment;
                },
                0
              )
            )
            .reduce((a, b) => a + b, 0) *
            this.model.quantity;
      }

      this.value.price = price.toFixed(2);

      return price.toFixed(2);
    },

    productOrderValid() {
      if(this.region.governance.EnableFeatureModifiers && this.modifiersValid.findIndex((x) => !x) > -1) return false;

      return this.modsValidationString == null || this.isMaxSelected;
    },

    modsValidationString() {
      if(this.getTotalMods < this.model.min_modifier_items) 
        return `Select at least ${this.model.min_modifier_items - this.getTotalMods} more option(s) to proceed.`;

      if(this.isMaxSelected) 
        return `You have selected the maximum of ${this.model.max_modifier_items} items.`;

      return null;
    },

    decrementIsDisabled() {
      return this.model.quantity <= 0;
    },
    incrementIsDisabled() {
      return this.remainingSlots <= 0 || this.model.quantity >= (this.value.max_per_customer || Infinity);
    },

    getTotalMods() {
      return this.model?.modifiers?.reduce((sum, x) => sum + x.items.reduce((partialSum, a) => partialSum + a.item_quantity, 0), 0);
    },

    isMaxSelected() {
      return this.model.max_modifier_items && this.getTotalMods === this.model.max_modifier_items;
    },

    getIngredientsNames() {
      if(!this.model.ingredients) return ''
      // Used set for preventing duplicate ingredients
      const allIngredients = Array.from(new Set([...this.model.ingredients.map(item=>item.ingredient.description), ...this.getRecipesIngredients(this.model?.recipes)]))
      return allIngredients.join(' / ');
    },
  },
  components: {
    ProductModifiers,
    ProductReviews,
    Tag,
    ProductMacrosTable
  },
};
</script>
<style lang="scss">
@import '../../../styles/variables.module.scss';

.order-product-description {
  word-wrap: break-word;
  overflow-wrap: break-word;
  ol {
    counter-reset: unset;
  }

  ol li {
    counter-increment: unset;
  }

  ol li:before {
    content: unset;
    color: var(--primary);
    font-weight: bold;
  }

  li {
    display: list-item;
  }
}
.modal-header .modal-title {
  font-size: 2em !important;
}

.modal-header .close {
  font-size: 2.75em !important;
}
</style>
<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

@include buttons;

.text-reviews,
.ingredients-label {
  font-family: $staatliches-font;
}

.text-reviews {
  color: black;
}

.product-image {
  max-width: 170px;
  border: var(--primary) 1px solid;
  border-radius: 5px;
  background-color: var(--primary);
}

.title-class {
  font-size: 1.75em !important;
}

.dollar {
  color: grey;
}
.right-product-column {
  p {
    color: grey;
  }
}

.badge {
  font-size: 14px;
}

.macros-table {
  &-header {
    color: white;
    background-color: var(--primary) !important;
  }
}

.footer-btn {
  border-radius: 0 !important;
  text-shadow: none !important;
  font-size: 1.3em;
  letter-spacing: 0.09em;
  padding: 5px !important;
  max-height: 60px !important;
  min-height: 60px !important;
  overflow: hidden;

  &:active {
    background-color: var(--primary);
  }

  &:disabled {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
  }

  &:hover {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
  }
}

.footer-btn-add {
  max-width: unset;
  background-color: black;
  border-color: black;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.btn-circle {
  padding: 0;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  line-height: 25px;
}

::v-deep .modal-body {
  padding-bottom: 0;
}

::v-deep .modal-footer {
  padding: 0;
}

::v-deep .modal-content {
  border: 0;
}

::v-deep .b-rating-value {
  padding-right: 0;
}

@media #{$belowMd} {
  ::v-deep .modal-dialog {
    margin: 0 auto;
    height: 100%;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    .modal-content {
      max-height: unset;
    }
  }
}

.modal-footer {
  background-color: black !important;
}

.vendor-logo {
  max-width: 40px;
  max-height: 40px;
}

.clearCart{
  margin: 10px 0px;
  font-size: 0.8rem;
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: sans-serif;
  background: #721c24;
  border: #721c24 2px solid;
}

.error-text{
  font-weight: bold;
  color: red;
}

.max-text{
  font-weight: bold;
}

.product-order-footer {
  &__price {
    font-size: 16px;
    font-weight: 400;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}
</style>

<style>
.product-order-modal-full-height {
    height: 100svh;
    margin: 0;
    padding: 0;
}

.product-order-modal-full-height .modal-dialog {
    height: 100%;
    margin: 0 auto;
}

.product-order-modal-full-height .modal-content {
    height: 100%;
    margin: 0 auto;
}

.product-order-modal-content-container {
  overflow-y: scroll;
}

#container {
    max-width: 1000px;
    width: 100%;
    background-color: white;
    height: 100%;
    opacity: .5;
    top: 0;
    position: fixed;
}

.no-scroll{
  overflow-x: hidden;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
} 
</style>

