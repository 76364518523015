import frequency1 from '~/../assets/icons/frequency/frequency1.png';
import frequency2 from '~/../assets/icons/frequency/frequency2.png';
import frequency3 from '~/../assets/icons/frequency/frequency3.png';
import frequency4 from '~/../assets/icons/frequency/frequency4.png';
import frequency5 from '~/../assets/icons/frequency/frequency5.png';
import frequency6 from '~/../assets/icons/frequency/frequency6.png';
import frequency7 from '~/../assets/icons/frequency/frequency7.png';

const frequencyIcon = {
  frequency1,
  frequency2,
  frequency3,
  frequency4,
  frequency5,
  frequency6,
  frequency7,
};

export default frequencyIcon;
