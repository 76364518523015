
<div class="rotating-subscription-card">
  <div class="rotating-subscription-img-wrapper" @mouseover="hover = true" @mouseleave="hover = false"
       @click="$emit('add-to-cart', value)">
    <div class="rotating-subscription-img-wrapper__additional-info"
         :style="(cutOffString.length > 0 ? 'top:15px !important;':'')">
    </div>

    <div v-if="value.cutoff_day_override !== null" class="rotating-subscription-card__cut-off">
      Order by {{ cutOffString }}
    </div>

    <b-badge v-if="!value.use_product_prices"  variant="dark" class="rotating-subscription-img-wrapper__price"
             :style="(cutOffString.length > 0 ? 'top:15px !important;':'')">
      <Currency
          prepend="$"
          :currency="value.price_override"
          :append="pricingModel"
          :secondary-font-size="'0.825em'"
      />
    </b-badge>

    <b-img v-if="value.image_url" class="rotating-subscription-img" :alt="value.name" :src="value.image_url"
           :title="value.name" fluid lazy @error="value.image_url = null"/>

    <div v-else class="image-placeholder">
      <div>{{ imagePlaceholder(value.name) }}</div>
    </div>

  </div>

  <div class="rotating-subscription-card__title truncate">
    {{ value.name }}
  </div>


  <div v-if="isRegular" @mouseover="hover = true" @mouseleave="hover = false" style="margin-top: auto">
    <b-button :disabled="outOfStock" block class="rotating-subscription-card__submit"
              @click="$emit('create-subscription', value)">
      Order
    </b-button>
  </div>

  <b-row align-h="center"
         v-if="region.governance.EnableFeatureInventory || (isSmartWeight && !region.governance.EnableFeatureSmartWeight)">
    <b-form-invalid-feedback v-if="region.governance.EnableFeatureInventory" id="inputLiveFeedback"
                             class="rotating-subscription-quantity-feedback mb-0"> Enter a number
    </b-form-invalid-feedback>
    <b-form-text v-else id="inputLiveHelp" class="rotating-subscription-quantity-feedback">
      SmartWeight&trade; products aren't available in the
      {{ region.name }} region yet. Check back soon.
    </b-form-text>
  </b-row>
</div>
