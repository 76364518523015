
<b-row v-if="loading" id="container" align-h="center" align-v="center">
  <b-col class="mx-auto">
    <b-img :src="loadingGif" class="w-100" fluid></b-img>
  </b-col>
</b-row>
<b-modal
  v-else
  v-model="active"
  title-class="h3"
  body-class="product-order-modal-content-container"
  modal-class="product-order-modal-full-height"
  no-close-on-backdrop
  lazy
  @hidden="$emit('hidden', null)"
>
  <template #modal-title>
    <b-col class="p-0">
      <p class="h3">{{ model.title }}</p>
      <b-row v-if="!$store.getters.vendor" class="ml-0" align-v="center">
        <b-img
          v-if="vendor.logo_image"
          :alt="vendor.name"
          :src="vendor.logo_image"
          :title="vendor.name"
          rounded
          lazy
          class="vendor-logo"
          @error="vendor.logo_image = null"
        />
        <p class="h5 ml-2 mb-0">{{ vendor.name }}</p>
      </b-row>
    </b-col>
  </template>
  <b-row
    class="justify-content-between mb-3"
    align-h="center"
    align-v="center"
  >
    <b-col cols="auto">
      <b-form-rating
        v-model="model.rating"
        class="pl-0"
        color="gold"
        inline
        no-border
        readonly
        show-value
        @click.prevent="viewingRatings = cloneDeep(model)"
      />
    </b-col>
    <b-row class="mr-2">
      <b-col cols="auto">
        <h3>
          <a
            href="#"
            class="text-right text-reviews"
            @click.prevent="viewingRatings = cloneDeep(model)"
            >{{ model.ratings_count }} Reviews</a
          >
        </h3>
      </b-col>
    </b-row>
  </b-row>

  <b-row align-v="center">
    <b-col md="auto" cols="6">
      <b-img
        v-if="model.image"
        class="product-image shadow"
        :alt="model.title"
        :src="model.image"
        :title="model.title"
        fluid
        lazy
        @error="model.image = null"
      />
      <div v-else class="image-placeholder">
        <div>{{ imagePlaceholder(value.title) }}</div>
      </div>
    </b-col>
    <b-col>
      <b-row wrap>
        <b-col
          v-for="tag in model.tags"
          :key="tag.name"
          md="6"
          sm="12"
          xs="12"
          class="mb-2"
        >
          <Tag
            size="sm"
            :value="tag"
            class="d-inline-flex"
          />&nbsp;
          <span class="text-muted small">{{ tag.name }}</span>
        </b-col>
      </b-row>
    </b-col>
  </b-row>

  <b-row v-if="model.description" align-h="center">
    <b-col cols="12" class="order-product-description pb-2">
      <h3 class="mt-4">Description</h3>
      <div class="small" v-html="model.description"></div>
    </b-col>
  </b-row>

  <b-row v-if="model.show_ingredients && getIngredientsNames.length" align-h="center">
    <b-col cols="12">
      <h3 class="mt-4">Ingredients</h3>
      <p class="small">
        {{getIngredientsNames}}
      </p>
    </b-col>
  </b-row>

  <hr v-if="model.modifiers" class="mt-2" />

  <b-row class='no-scroll'>
    <template>
      <product-modifiers
        v-if="model.modifiers"
        v-model="model.modifiers"
        :validated.sync="modifiersValid"
        :is-mods-disabled = "isMaxSelected"
        expanded
        @model-changed="handleModelChanged"
      />
    </template>
  </b-row>

  <template #modal-footer>
    <b-row
      class="justify-content-between w-100 m-0 macros-table"
      align-h="center"
      align-v="center"
    >
      <div v-if="modsValidationString" :class="['p-1 text-center mx-auto', isMaxSelected ? 'max-text' : 'error-text']">
        {{ modsValidationString }}
      </div>
      <ProductMacrosTable :macros="macros" :nutritions="nutritionRecommendation" :isGoalCenter="vendor.settings.enable_goal_center" />
    </b-row>
    <b-row class="w-100 m-0 product-order-footer" align-v="center">
      <b-col
        v-if="!model.max_weekly_frequency"
        cols="4"
        class="px-0 d-flex justify-between align-items-center"
      >
        <b-btn-group class="w-100">
          <b-btn
            class="footer-btn btn"
            :disabled="decrementIsDisabled"
            @click="decrementQuantity"
          >
            <b-icon-dash scale="1.5" />
          </b-btn>
          <b-btn
              class="footer-btn btn"
              :disabled="incrementIsDisabled"
              @click="incrementQuantity"
          >
            <b-icon-plus scale="1.5" />
          </b-btn>
        </b-btn-group>
      </b-col>
      <b-col class="px-0">
        <b-btn
          class="float-right footer-btn footer-btn-add"
          :disabled="!productOrderValid"
          block
          @click="addToCart()" 
        >
        <template v-if="productOrderValid">
          <span class="small">Add</span>
             {{ model.quantity }}
          <span class="small"
            >Item{{ model.quantity > 1 ? 's' : '' }}
          </span>
          <span class="small">To Order </span>
          <span class="mdi mdi-cart cart-icon" />
          <span v-if="rotatingSubscription.use_product_prices || rotatingSubscription.pricing_model === 'PER_MEAL' && completePrice" class="product-order-footer__price">
            &ndash;
            <span>$</span>
            <number
              :to="completePrice"
              :duration="0.12"
              :format="formatPriceToCompact"
            />
          </span>
        </template>
        <template v-else>
          Customize To Proceed
        </template>
        </b-btn>
      </b-col>
    </b-row>
  </template>
  <product-reviews v-if="viewingRatings" v-model="viewingRatings" />
</b-modal>
