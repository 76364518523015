<template>
  <div class="" v-if="macros.length || macros.calories">
    <div
      class="content-wrapper"
      v-if="macros.length || macros.calories"
      :class="{
        tabContentWrapper: !changeTab,
      }"
    >
      <div class="wrapper mt-5">
        <h3 class="header">Edit Your Macros</h3>
        <div class="form macrosForm">
          <b-form-group label="Calories">
            <b-form-input
              v-model="macros.calories"
              :rules="[rules.required]"
              label="Calories"
              class="mr-2"
            />
          </b-form-group>
          <b-form-group label="Proteins (Grams)">
            <b-form-input
              v-model="macros.protein"
              :rules="[rules.required]"
              label="Protein"
              class="mr-2"
            />
          </b-form-group>
          <b-form-group label="Carbohydrates (Grams)">
            <b-form-input
              v-model="macros.carbs"
              :rules="[rules.required]"
              label="Carbs"
              class="mr-2"
            />
          </b-form-group>
          <b-form-group label="Fats (Grams)">
            <b-form-input
              v-model="macros.fat"
              :rules="[rules.required]"
              label="Fat"
              class="mr-2"
            />
          </b-form-group>
        </div>
      </div>
      <b-row align-h="center">
        <my-button :on-click="saveUserMacros" center :disbled="loading">
          Save
        </my-button>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SteakIcon from '@/common/Icons/SteakIcon.vue';
import FatIcon from '@/common/Icons/FatIcon.vue';
import LockIcon from '@/common/Icons/LockIcon.vue';
import CarbsIcon from '@/common/Icons/CarbsIcon.vue';
import InfoCard from '@/common/InfoCard/InfoCard.vue';
import Skeleton from '@/common/Skeleton/Skeleton.vue';
import IconBase from '@/common/Icons/IconBase.vue';
import Button from '@/common/Button/Button.vue';

import { axios } from '^/axios';
import { rules } from '^/rules';

export default {
  props: {
    changeTab: { type: Function, required: true },
  },
  components: {
    InfoCard,
    Skeleton,
    LockIcon,
    IconBase,
    MyButton: Button,
  },
  computed: {
    ...mapGetters(['user', 'vendor']),
    numberValue: {
      get(value) {
        return Math.floor(value);
      },
    },
  },
  data() {
    return {
      nutritionRecommendation: null,
      SteakIcon,
      FatIcon,
      CarbsIcon,
      loading: true,
      disabled: true,
      isSavingUserMarcors: false,
      isDeletingUserMacros: false,
      macros: {
        calories: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
      },
      rules,
    };
  },
  created: async function () {
    await this.getNutritionRecommendation();
  },

  methods: {
    buttonClick() {
      this.changeTab(3);
    },
    async saveUserMacros() {
      this.isSavingUserMarcors = true;

      try {
        this.loading = true;
        let url = `vendor/${this.vendor.uuid}/user/${this.user.uuid}/macros`;

        if (this.macros.id) {
          this.macros = await axios.put(url, this.macros);
        } else {
          this.macros = await axios.post(url, this.macros);
        }
        await this.$store.dispatch('alertError', 'You updated your macros!');
      } catch (error) {
        await this.$store.dispatch('alertError', error.message);
      } finally{
        window.scrollTo(0, 0);
      }

      this.$emit('getNutritionRecommendation');
      this.isSavingUserMarcors = false;
      this.loading = false;
    },
    async getNutritionRecommendation() {
      this.loading = true;
      console.log(this.user);
      try {
        const response = await axios.get(
          `/user/${this.user.uuid}/nutrition-recommendations`
        );
        this.macros = response;
        if (!response.calories) {
          this.changeTab(1);
          this.disabled = true;
        }
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.macrosForm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .macrosForm {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
  }

  .form .b-form-group {
    width: 100%;
  }
}

.header {
  font-size: 20px;
  font-weight: bold;
  font-family: $main-font;
}
</style>
