
<b-row align-h="center" justify="center">
  <b-row
      align-h="center"
      justify="center"
      v-if="products.length > 0"
      class="text-center products-list mx-auto"
  >
    <product
        v-for="(product, x) in products"
        :key="x"
        v-model="products[x]"
        :remaining-slots="remainingSlots"
        :rotating-subscription="rotatingSubscription"
        :view-reviews="viewReviews"
        :can-edit="canEdit"
        @toggle-favorite="toggleFavorite"
        @add-to-subscription="triggerOrderModal"
    />
  </b-row>

  <b-row v-else-if="loading" key="loading" class="text-center no-products pt-3">
    <b-col xs12>
      <h1>Loading</h1>
      <p>Please wait.</p>
    </b-col>
  </b-row>

  <product-info v-if="viewProductInfo" v-model="viewProductInfo" :view-reviews="viewReviews"/>
  <product-order-modal v-if="orderProduct" v-model="orderProduct" :vendor_uuid="vendor.uuid"
                       :nutrition-recommendation="nutritionRecommendation" @hidden="orderProduct = null"
                       :meals-selected="mealsSelected" :rotating-subscription="rotatingSubscription"
                       @add-product-to-subscription="addProductToSubscription"/>
  <product-reviews v-if="viewProductReviews && viewProductReviews.uuid" v-model="viewProductReviews"/>
</b-row>
