<template>
  <b-container>
    <div v-if="loading"></div>
    <div v-else class="wrapper">
      <tabs-wrapper
        v-if="isSmallScreen"
        ref="tabsWrapper"
        :active-color="getPersonalization().theme_color_1"
        :tabs="tabs"
      >
        <div slot="tab1">
          <macros :change-tab="changeTab" />
          <div id="editMacros" v-if="vendor.settings.enable_edit_macros">
            <EditMacros
              ref="editMacros"
              :change-tab="changeTab"
              @getNutritionRecommendation="getNutritionRecommendation"
            />
          </div>
        </div>
        <div slot="tab2">
          <basic v-once :on-save-finished="onBasicsInfoUpdated" />
        </div>
        <div slot="tab3">
          <progress-component :on-save-finished="onHistoryUpdated" />
        </div>
      </tabs-wrapper>
      <div v-else class="macros">
        <div id="macronutrients" ref="mc" class="content-card-shadow">
          <macros ref="macronutrients" :change-tab="changeTab" />
        </div>
        <div id="basic" class="content-card content-card-shadow">
          <basic
            ref="basics"
            :on-save-finished="updateMacros"
            :change-tab="changeTab"
          />
        </div>
        <div id="progress" class="content-card content-card-shadow">
          <progress-component
            :on-save-finished="updateMacros"
            :change-tab="changeTab"
          />
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import MacrosIcon from '@/common/Icons/MacrosIcon.vue';
import ScaleIcon from '@/common/Icons/ScaleIcon.vue';
import ProgressIcon from '@/common/Icons/ProgressIcon.vue';
import TabsWrapper from '@/common/Tabs/TabsWrapper.vue';
import Macros from '@/Macronutrients/Macros/Macros.vue';
import EditMacros from '@/Macronutrients/Macros/EditMacros.vue';
import Basic from '@/Macronutrients/Basic/Basic.vue';
import Progress from '@/Macronutrients/Progress/Progress.vue';
import { axios } from '^/axios';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  components: {
    TabsWrapper,
    Macros,
    Basic,
    ProgressComponent: Progress,
    EditMacros,
  },

  data() {
    return {
      tabs: [
        {
          title: 'Macros',
          id: 'tab1',
          icon: MacrosIcon,
          disabled: true,
        },
        {
          title: 'Basics',
          id: 'tab2',
          icon: ScaleIcon,
        },
        {
          title: 'Progress',
          id: 'tab3',
          icon: ProgressIcon,
        },
      ],
      activeColor: '',
      ScaleIcon,
      screenWidth: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['user', 'vendor']),
    isSmallScreen() {
      return this.screenWidth <= 991;
    },
  },

  methods: {
    changeTab(tabIndex) {
      if (this.$refs.tabsWrapper) {
        this.$refs.tabsWrapper.setActiveTab(tabIndex);
      }
    },

    getPersonalization() {
      const personalization =
        JSON.parse(localStorage.getItem('personalization')) || {};

      return personalization;
    },

    async onBasicsInfoUpdated() {
      const lastWeight = await this.getLastWeight();
      if (lastWeight[0]) {
        this.tabs[0].disabled = false;
        this.changeTab(0);
      } else {
        this.changeTab(2);
      }
    },

    async onHistoryUpdated() {
      const basics = await this.isBasicInfoExist();
      if (basics) {
        this.tabs[0].disabled = false;
      }
    },

    async isBasicInfoExist() {
      const basics = await this.getNutritionRecommendation();
      return basics.calories ? true : false;
    },

    async isWeightHistoryExist() {
      const weight = await this.getLastWeight();
      return weight && weight[0] ? true : false;
    },

    async shouldBasicTabBeDisabled() {
      const basicInfo = await this.isBasicInfoExist();
      const weightHistory = await this.isWeightHistoryExist();

      if (!basicInfo || !weightHistory) {
        this.tabs[0].disabled = true;
        this.changeTab(1);
      }

      if (basicInfo && weightHistory) {
        this.tabs[0].disabled = false;
        this.changeTab(0);
      }
    },

    async updateMacros() {
      const lastWeight = await this.getLastWeight();
      const basicInfo = await this.isBasicInfoExist();

      if (lastWeight[0] && basicInfo) {
        this.changeTab(0);
        this.makeNutritionRecommendationActive();
        if (this.$refs.macronutrients) {
          this.$refs.macronutrients.getNutritionRecommendation();
        }
      } else {
        this.changeTab(2);
      }
    },

    handleResize() {
      this.screenWidth = window.innerWidth;
    },

    makeNutritionRecommendationActive() {
      this.tabs[0].disabled = false;
    },

    async getNutritionRecommendation() {
      try {
        const response = await axios.get(
          `/user/${this.user.uuid}/nutrition-recommendations`
        );
        return response;
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      }
    },

    async getLastWeight() {
      try {
        const response = await axios.get(
          `/user/${this.user.uuid}/physicality/weight`,
          {
            params: {
              start_date: moment('1971', 'YYYY').toDate(),
              end_date: moment().toDate(),
              per_page: 1,
            },
          }
        );
        return response.data;
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const vendor = vm.$store.getters.vendor;
      if (vendor.settings.enable_goal_center) {
        next();
      } else {
        // If enable_goal_center === false, redirect to main page
        next({ path: '/' });
      }
    });
  },
  async mounted() {
    this.loading = true;
    await this.shouldBasicTabBeDisabled();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.loading = false;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables.module.scss';

.wrapper {
  font-family: $main-font;
}
.macros {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'macronutrients basic'
    'editMacros editMacros'
    'progress progress';
  grid-template-rows: auto;
  gap: 20px;
  margin-top: 40px;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
}
#editMacros {
  grid-area: editMacros;
  width: 100%;
}
#macronutrients {
  grid-area: macronutrients;
  display: flex;
  align-items: center;
  justify-content: center;
}
#basic {
  grid-area: basic;
  max-height: auto;
}
#progress {
  grid-area: progress;
}

.content-card {
  padding: 40px;
}

.showAdvanced {
  max-height: 100% !important;
}

.content-card-shadow {
  transition: all 1s ease;
  border-radius: 20px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
}
</style>
