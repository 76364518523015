
<b-container class="cart-container">
  <b-row
    v-if="!orderRequirementsMet && cartProducts.length !== 0 && false"
    align-h="center"
    class="mt-4"
  >
    <b-container>
      <b-alert v-if="!fetchingQuote" show variant="warning">
        <order-requirements-notice />
      </b-alert>
    </b-container>
  </b-row>

  <b-alert v-if="!isPending && !meetsMinimumSubtotal && vendor.minimum_order_subtotal"
    class="alert-toast mb-5"
    variant="warning" show >
    You need a subtotal of at least ${{ vendor.minimum_order_subtotal }} to proceed!
  </b-alert>

  <b-alert v-else-if="!isPending && !address && (orderType === order_types.delivery || orderType === order_types.shipping)" 
    class="alert-toast mb-5"
    variant="warning" show >
    You need to choose address for delivery or shipping before proceeding.
  </b-alert>

  <div class="row justify-center pt-3 pb-0">
    <div class="col-12">
      <h1 class="display-4 align-center text-center">Your Cart</h1>

      <h4 v-if="region" class="text-center px-1">
        You are ordering in
        <span class="mdi mdi-map-marker-radius-outline"></span>
        {{ region.name }}
      </h4>
    </div>
  </div>

  <b-container v-if="cartProducts.length === 0">
    <b-row align-h="center" class="empty-message text-center pt-3">
      <p>Your cart is empty!</p>
    </b-row>
    <b-row align-h="center" class="mt-3">
      <b-button class="btn-cta" to="/">Shop</b-button>
    </b-row>
  </b-container>
  <template v-else>
    <template v-if="rotatingSubscription">
      <hr>
      <h2 style="text-align: center">
        {{ rotatingSubscription.name }}

        <b-btn class="float-right btn toggle-btn" @click="isOpen = !isOpen">
          <b-icon-caret-right-fill v-if="!isOpen" size="md" />
          <b-icon-caret-down-fill v-else size="md" />
        </b-btn>
      </h2>
      <hr>

      <b-collapse v-model="isOpen">
        <CartRotatingSubscription v-model="rotatingSubscription" />
      </b-collapse>
    </template>

    <template v-else>
      <div v-for="(_, index) in cartProducts" :key="index">
        <CartProduct v-model="cartProducts[index]" />
      </div>

      <ProductMacrosTable :macros="totalMacros" :isGoalCenter="vendor.settings.enable_goal_center" />
    </template>

    <h1 class="mt-5 mb-4 text-center">Choose Your Style</h1>
    <b-button-group class="w-100 text-center mx-auto order-type-buttons">
      <b-button
        v-if="vendorRegion.delivery_enabled"
        :class="orderType === order_types.delivery ? 'btn' : 'btn-outline'"
        @click="updateOrderType(order_types.delivery)"
        >{{ order_types.delivery }}
      </b-button>
      <b-button
        v-if="vendorRegion.enable_pickup"
        :class="orderType === order_types.pickup ? 'btn' : 'btn-outline'"
        @click="updateOrderType(order_types.pickup)"
        >{{ order_types.pickup }}
      </b-button>
      <b-button
        v-if="vendorRegion.enable_shipping"
        :class="orderType === order_types.shipping ? 'btn' : 'btn-outline'"
        @click="updateOrderType(order_types.shipping)"
        >{{ order_types.shipping }}
      </b-button>
    </b-button-group>

    <b-row
      v-if="
        orderType === order_types.delivery ||
        orderType === order_types.shipping
      "
      class="mt-5"
      align-h="center"
    >
      <b-col cols="12">
        <h1 class="text-center">To Where?</h1>
      </b-col>

      <b-tabs v-model="tabs" class="tabs w-100 mx-3" align="center" justified>
        <b-tab title="Add an Address">
          <template v-if="addressLoading">
            <b-row align-h="center">
              <b-img :src="loadingGif" class="mx-auto" height="170"></b-img>
            </b-row>
          </template>
          <div v-else class="py-4">
            <div>
              <Address
                v-model="selectedAddressAutocomplete"
                label="Address"
                @clear="clearAddress"
              />
            </div>
            <div class="mt-2">
              <my-input
                v-model="selectedAddressAutocomplete.unit"
                :disabled="false"
                placeholder="Unit/Suite"
                :label="'Unit/Suite'"
              >
              </my-input>
            </div>
            <b-row align-h="center" justify="center">
              <b-button
                :disabled="!selectedAddressAutocomplete.description"
                @click="saveAddress"
              >
                Save Address
              </b-button>
            </b-row>
          </div>
        </b-tab>

        <b-tab title="My Addresses">
          <template v-if="addressLoading">
            <b-row align-h="center">
              <b-img :src="loadingGif" class="mx-auto" height="170"></b-img>
            </b-row>
          </template>

          <div v-else-if="addresses.length" class="py-4 text-center">
            <b-form-radio-group v-model="address" @input="selectAddress">
              <div v-for="addr in getAddressesToDisplay()" :key="addr.id">
                <AddressComponent :address="addr">
                  <template #select>
                    <b-radio :value="addr.id" />
                  </template>

                  <template #delete>
                    <b-icon icon="trash" class="text-danger address-action" @click="onShowDeleteAddressModal(addr)"/>
                  </template>
                </AddressComponent>
              </div>

              <b-modal :visible="showDeleteAddressModal" hide-header-close no-close-on-esc no-close-on-backdrop cancel-disabled hideFooter centered :title="`Are you sure you want to remove ${removedAddress && removedAddress.autocomplete} from your addresses?`">
                <div class="delete-address-modal-footer">
                  <b-button variant="primary" :disabled="isAddressRemoving" @click="onCloseDeleteAddressModal">Cancel</b-button>
                  <b-button class="delete-address-modal-footer__delete-button" :disabled="isAddressRemoving" @click="handleDeleteAddress()">Remove</b-button>
                </div>
              </b-modal>
            </b-form-radio-group>

            <div v-if="addresses && addresses.length > 3" class="py-2">
              <a
                href="#"
                @click.prevent="hideMoreAddresses = !hideMoreAddresses"
              >
                <span v-if="hideMoreAddresses">Show more</span>
                <span v-else>Show less</span>
              </a>
            </div>
          </div>
          <div v-else class="py-5 text-center">
            No addresses was added. Please add one.
          </div>
        </b-tab>
      </b-tabs>
    </b-row>

    <b-row v-if="orderType === order_types.shipping">
      <b-col cols="12 mt-5 mb-4">
        <h1 class="text-center">Shipping options</h1>
      </b-col>
      <b-col class="text-center">
        <b-dropdown
          id="dropdown-1"
          text="Dropdown Button"
          class="selectBox text-center"
          toggle-class="button"
          menu-class="menu"
          link-class="dropdown-item"
        >
          <template #button-content>
            <span>{{
              vendorRegion.shipping_rates.find(
                (shipping_rate) => shipping_rate.id === shippingRateId
              ).shipping_label
            }}</span>
          </template>
          <div v-for="product in vendorRegion.shipping_rates" :key="product.id">
            <b-dropdown-item
              class="dropdownItem"
              @click="setShippingRate(product)"
              ><div>{{ product.shipping_label }}</div>
              <div>$ {{ product.shipping_rate }}</div></b-dropdown-item
            >
          </div>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-row
      v-show="(vendor.settings.enable_tipping && orderType === order_types.delivery) || (vendor.settings.enable_tipping_pickup && orderType === order_types.pickup)"
      class="mt-4"
      align-h="center"
    >
      <b-col cols="12">
        <h1 class="text-center">Tip?</h1>
      </b-col>

      <Tip :selectedTip="selectedTip" @change="onUpdateTip" :orderType="orderType"/>
    </b-row>
    <div>
      <b-row class="mt-5" align-h="center" justify="center" align-v="center">
        <b-col cols="12">
          <h1 class="text-center">Promo Code</h1>
        </b-col>
        <b-col cols="auto">
          <b-input
            v-model="discountCode"
            class="discount-input text-center p0 m0"
            placeholder="Discount Code?  "
            type="text"
            @keyup="onChangeDiscount"
          />
        </b-col>
      </b-row>

      <b-row  v-if="giftCards.balance && vendor.settings.enable_gift_cards" align-h="center" justify="center" align-v="center">
        <b-col cols="12">
          <h1 class="mt-4 mb-3 text-center">Gift Card</h1>
        </b-col>

        <b-col cols="auto">
          <b-form-group>
            <template #description>
              <div style="text-align: center;">Gift Card Balance: {{ formatDefaultPrice(giftCards.balance) }}</div></template>
            <b-form-input 
              class="discount-input" 
              v-model.trim="gift_card_credit" 
              placeholder="Use funds from your gift card?" 
              :min="0" 
              :max="maxGiftCardSum" 
              type="number"
              @keyup="onChangeGiftCardDiscount" 
              @input="removeLeadingZero"
              step="0.01"
            />
          </b-form-group>
        </b-col>

        <b-col v-if="giftCardFundsError" cols="12" class="mt-4">
          <b-alert variant="warning" show>
            Gift card funds used must be greater than $0 and less than ${{ maxGiftCardSum }}.
          </b-alert>
        </b-col>
      </b-row>

      <template
        v-if="
          (orderType === order_types.delivery && address) ||
          orderType === order_types.shipping ||
          orderType === order_types.pickup
        "
      >
        <b-row class="cart-totals" justify="center">
          <b-container v-if="!addressIsRequiredButEmpty">
            <div class="row justify-center pt-5">
              <div class="col-12">
                <h1 class="display-4 align-center text-center">
                  Order Summary
                </h1>
              </div>
            </div>

            <template v-if="fetchingQuote">
              <b-row align-h="center">
                <b-img :src="loadingGif" class="mx-auto" height="170"></b-img>
              </b-row>
            </template>
            <template v-else-if="!quote">
              <p class="align-center text-center">
                {{ alert.text }}
              </p>
            </template>
            <template v-else>
              <b-container style="max-width: 600px">
                <CostBreakdown :value="quote" />
              </b-container>
            </template>
          </b-container>
        </b-row>
      </template>
      
      <b-alert v-if="!meetsMinimumSubtotal && vendor.minimum_order_subtotal" 
        class="alert-toast mb-5"
        variant="warning" show >
        You need a subtotal of at least ${{ vendor.minimum_order_subtotal }} to proceed!
      </b-alert>
      
      <b-alert v-if="!vendor.is_active_payment" 
        class="alert-toast mb-5"
        variant="warning" show >
        This website is under construction. Check back soon!
      </b-alert>
              
      <b-row ref="cta" align-h="center" class="my-4">
        <b-button
          v-if="auth.isAuthenticated()"
          class="btn-cta"
          to="/checkout"
          :disabled="disableCheckout"
          >Continue</b-button
        >
        <b-button
          v-else
          class="btn-cta"
          to="/validate?redirect=/checkout"
          :disabled="!cartValid || !meetsMinimumSubtotal"
          >Continue</b-button
        >
      </b-row>
    </div>
  </template>    
</b-container>
