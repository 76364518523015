<template>
  <b-col cols="24">
    <ProductGrid
        key="products"
        :products="selectedProducts"
        :loading="loading"
        :rotating-subscription="model"
        :meals-selected="mealsSelected"
        :can-edit="false"
    ></ProductGrid>

    <b-col cols="12">
      <b-row
          class="justify-content-between w-100 m-0 macros-table"
          align-h="center"
          align-v="center"
      >
      </b-row>
      <b-row class="w-100 m-0 product-order-footer" align-v="center">
        <b-col class="px-0" cols="6">
          <b-btn
              class="float-right footer-btn footer-btn-add"
              block
              @click="clearCart"
          >
            <span class="small">Remove </span>
          </b-btn>
        </b-col>
        <b-col class="px-0" cols="6">
          <b-btn
              class="float-right footer-btn footer-btn-add"
              :disabled="!rotatingSubscriptionOrderValid"
              block
              @click="updatedRotatingSubscription = value"
          >
            <span class="small">Modify </span>
          </b-btn>
        </b-col>
      </b-row>
    </b-col>

    <rotating-subscription-order-modal
        v-if="updatedRotatingSubscription"
        :value="updatedRotatingSubscription"
        :vendor_uuid="vendor.uuid"
        :currently-selected-products="selectedProducts"
        @hidden="updatedRotatingSubscription = null"
    />
  </b-col>
</template>

<script>
import {formatPriceToCompact} from '%/formatPrice';
import loadingGif from '~/../assets/loading.gif'
import ProductModifiers from '@/shop/product-partials/ProductModifiers.vue';
import ProductReviews from '@/shop/product-partials/ProductReviews.vue';
import {cloneDeep} from 'lodash';
import {mapGetters} from 'vuex';
import Tag from '^/components/Tag.vue';
import {axios} from '^/axios';
import {configuration} from '~/configuration';

import {imagePlaceholder, removeCart} from '^/utilities';
import ProductMacrosTable from '@/Macronutrients/Macros/ProductMacrosTable.vue';
import ProductGrid from "@/shop/rotating-subscription-partials/ProductGrid.vue";
import Schedule from "@/shop/rotating-subscription-partials/Schedule.vue";
import RotatingSubscriptionOrderModal from "@/shop/rotating-subscription-partials/RotatingSubscriptionOrderModal.vue";

export default {
  name: 'CartRotatingSubscription',
  props: ['value'],
  data() {
    return {
      loading: false,
      active: false,
      model: {
        name: String(),
        rotatingSubscription: true,
        quantityOrdered: 1,
        image_url: String(),
        cutoff_day_override: Number(),
        cutoff_time_override: Date,
        id: Number(),
        is_customer_selected: Boolean(),
        max_items: Number(),
        min_items: Number(),
        price_override: Boolean(),
        pricing_model: String(),
        region_id: Number(),
        subscription_id: Number(),
        use_product_prices: Boolean(),
        uuid: String(),
        vendor_id: Number(),
      },
      updatedRotatingSubscription: false,
      products: [],
      selectedProducts: [],
      subscription: {},
      daysOfWeek: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      cloneDeep,
      configuration,
      loadingGif
    };
  },
  watch: {
    value(val) {
      this.active = val != null;
      this.selectedProducts = val ? val.products : [];
    },

  },
  async created() {
    this.loading = true;
    this.model = cloneDeep(this.value);
    if (!this.model.quantityOrdered) {
      this.model.quantityOrdered = 1;
    }
    this.subscription = (await this.getSubscription());
    this.selectedProducts = this.value.products
    this.$forceUpdate();
    this.loading = false;
    this.active = true;
  },
  methods: {
    formatPriceToCompact,
    imagePlaceholder,
    removeCart,
    async getSubscription() {
      return await axios.get(`/rotating-subscription/${this.model.uuid}/subscription`);
    },
    formatToPrice(value) {
      return Math.max(value).toFixed(2);
    },
    clearCart() {
      this.removeCart();
      this.$bvToast.hide();
    },
  },
  computed: {
    ...mapGetters(['region', 'cartProducts', 'vendor']),
    completePrice() {
      let price = 0.0;

      if (this.model.use_product_prices) {
        price = Object.values(this.selectedProducts).reduce((totalPrice, product) => {
          return totalPrice + (parseFloat(product.price));
        }, 0.0);
      } else if (this.model.pricing_model === 'PER_MEAL') {
        price = Object.values(this.selectedProducts).reduce((totalPrice, product) => {
          return totalPrice + (parseFloat(product.price));
        }, 0.0);
      } else if (this.model.pricing_model === 'PER_TIMESLOT') {
        price = Object.values(this.selectedProducts).reduce((totalPrice, product) => {
          return totalPrice + (parseFloat(product.price));
        }, 0.0);
      }

      return this.formatToPrice(price);
    },
    rotatingSubscriptionOrderValid() {
      const mealsSelected = this.mealsSelected;
      return mealsSelected >= this.model.min_items && mealsSelected <= this.model.max_items;
    },
    mealsSelected() {
      return Object.values(this.selectedProducts).reduce((acc, item) => {
        return acc + (item.quantity || 0);
      }, 0);
    }
  },
  components: {
    RotatingSubscriptionOrderModal,
    Schedule,
    ProductGrid,
    ProductModifiers,
    ProductReviews,
    Tag,
    ProductMacrosTable
  },
};
</script>

<style lang="scss">
@import '../../../styles/variables.module.scss';

.order-product-description {
  word-wrap: break-word;
  overflow-wrap: break-word;

  ol {
    counter-reset: unset;
  }

  ol li {
    counter-increment: unset;
  }

  ol li:before {
    content: unset;
    color: var(--primary);
    font-weight: bold;
  }

  li {
    display: list-item;
  }
}

.modal-header .modal-title {
  font-size: 2em !important;
}

.modal-header .close {
  font-size: 2.75em !important;
}
</style>
<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

@include buttons;

.text-reviews,
.ingredients-label {
  font-family: $staatliches-font;
}

.text-reviews {
  color: black;
}

.product-image {
  max-width: 170px;
  border: var(--primary) 1px solid;
  border-radius: 5px;
  background-color: var(--primary);
}

.title-class {
  font-size: 1.75em !important;
}

.dollar {
  color: grey;
}

.right-product-column {
  p {
    color: grey;
  }
}

.badge {
  font-size: 14px;
}

.macros-table {
  &-header {
    color: white;
    background-color: var(--primary) !important;
  }
}

.footer-btn {
  border-radius: 0 !important;
  text-shadow: none !important;
  font-size: 1.3em;
  letter-spacing: 0.09em;
  padding: 5px !important;
  max-height: 60px !important;
  min-height: 60px !important;
  overflow: hidden;

  &:active {
    background-color: var(--primary);
  }

  &:disabled {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
  }

  &:hover {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
  }
}

.footer-btn-add {
  max-width: unset;
  background-color: black;
  border-color: black;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.btn-circle {
  padding: 0;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  line-height: 25px;
}

::v-deep .modal-body {
  padding-bottom: 0;
}

::v-deep .modal-footer {
  padding: 0;
}

::v-deep .modal-content {
  border: 0;
}

::v-deep .b-rating-value {
  padding-right: 0;
}

.modal-footer {
  background-color: black !important;
}

.vendor-logo {
  max-width: 40px;
  max-height: 40px;
}

.clearCart {
  margin: 10px 0px;
  font-size: 0.8rem;
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: sans-serif;
  background: #721c24;
  border: #721c24 2px solid;
}

.error-text {
  font-weight: bold;
  color: red;
}

.max-text {
  font-weight: bold;
}

.product-order-footer {
  &__price {
    font-size: 16px;
    font-weight: 400;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}
</style>

<style>
.product-order-modal-full-height {
  height: 100svh;
  margin: 0;
  padding: 0;
}

.product-order-modal-full-height .modal-dialog {
  height: 100%;
  margin: 0 auto;
}

.product-order-modal-full-height .modal-content {
  height: 100%;
  margin: 0 auto;
}

.product-order-modal-content-container {
  overflow-y: scroll;
}

#container {
  max-width: 1000px;
  width: 100%;
  background-color: white;
  height: 100%;
  opacity: .5;
  top: 0;
  position: fixed;
}

.no-scroll {
  overflow-x: hidden;
}

.delivery-schedule-col:not(:first-child) {
  border-left: 1px solid var(--primary);
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>