import ValidateUser from '@/cart/ValidateUser.vue'
import ResetPassword from '@/auth/ResetPassword.vue'
import OrderHistory from '@/order-history/OrderHistory.vue'
import Subscriptions from '@/subscriptions/Subscriptions.vue'
import Macronutrients from '@/Macronutrients/Macronutrients.vue'
import GiftCards from '@/GiftCards/GiftCards.vue'
import ForCouriers from '@/ForCouriers.vue'
import MapPage from '@/MapPage/MapPage.vue'
import Cart from '@/cart/Cart.vue'
import Checkout from '@/cart/Checkout.vue'
import UserSettings from '@/UserSettings/UserSettings.vue'
import { RolesEnum } from '^/auth'
import { configuration } from '~/configuration'
import store from '~/vuex'

const Vendor = () => import('@/vendor/Vendor.vue')
const SingleVendorHome = () => import('@/themes/SingleVendorHome.vue')
const Home = () => import('@/themes/DabDash.vue')

export const routeMap = {
  home: {
    path: '/',
    name: 'home'
  },
  forCouriers: {
    path: '/for-couriers',
    name: 'for-couriers'
  },
  category: {
    path: '/vendor/:vendorId/category/:categoryId',
    name: 'category'
  },
  vendor: {
    path: '/vendor/:vendorId',
    name: 'vendor'
  },
  map: {
    path: '/map',
    name: 'map'
  },
  reset: {
    path: '/reset/:token',
    name: 'reset'
  },
  resetNotify: {
    path: '/vendor/:vendorId/reset-notify',
    name: 'reset-notify'
  },
  cart: {
    path: '/cart',
    name: 'cart'
  },
  checkout: {
    path: '/checkout',
    name: 'checkout'
  },
  validate: {
    path: '/validate',
    alias: ['/login', '/register'],
    name: 'validate'
  },
  orderHistory: {
    path: '/order-history',
    name: 'order-history'
  },
  subscriptions: {
    path: '/subscriptions',
    name: 'subscriptions'
  },
  macronutrients: {
    path: '/macronutrients',
    name: 'macronutrients'
  },
  giftCards: {
    path: '/gift-cards',
    name: 'gift-cards'
  },
  userSettings: {
    path: '/user-settings',
    name: 'user-settings'
  },
  // rotatingSubscriptions: {
  //   path: '/rotating-subscriptions',
  //   name: 'rotating-subscriptions'
  // }
}

export const routes = [
  {
    path: routeMap.home.path,
    name: routeMap.home.name,
    component: store.getters.vendor ? SingleVendorHome : Home,
    meta: {
      public: true
    }
  },
  {
    path: routeMap.forCouriers.path,
    name: routeMap.forCouriers.name,
    component: ForCouriers,
    meta: {
      public: true
    }
  },
  {
    path: routeMap.reset.path,
    name: routeMap.reset.name,
    component: ResetPassword,
    meta: {
      public: true
    }
  },
  {
    path: routeMap.resetNotify.path,
    name: routeMap.resetNotify.name,
    component: ResetPassword,
    meta: {
      public: true
    }
  },
  {
    path: routeMap.map.path,
    name: routeMap.map.name,
    component: MapPage,
    meta: {
      public: true
    }
  },
  {
    path: routeMap.category.path,
    name: routeMap.category.name,
    component: store.getters.vendor ? SingleVendorHome : Vendor,
    meta: {
      public: true
    }
  },
  {
    path: routeMap.vendor.path,
    name: routeMap.vendor.name,
    component: store.getters.vendor ? SingleVendorHome : Vendor,
    meta: {
      public: true
    }
  },
  {
    path: routeMap.validate.path,
    name: routeMap.validate.name,
    alias: routeMap.validate.alias,
    component: ValidateUser,
    meta: {
      public: true
    }
  },
  {
    path: routeMap.cart.path,
    name: routeMap.cart.name,
    component: Cart,
    meta: {
      roles: [RolesEnum.Customer]
    }
  },
  {
    path: routeMap.checkout.path,
    name: routeMap.checkout.name,
    component: Checkout,
    meta: {
      roles: [RolesEnum.Customer]
    }
  },
  {
    path: routeMap.orderHistory.path,
    name: routeMap.orderHistory.name,
    component: OrderHistory,
    meta: {
      roles: [RolesEnum.Customer]
    }
  },
  {
    path: routeMap.subscriptions.path,
    name: routeMap.subscriptions.name,
    component: Subscriptions,
    meta: {
      roles: [RolesEnum.Customer]
    }
  },
  {
    path: routeMap.macronutrients.path,
    name: routeMap.macronutrients.name,
    component: Macronutrients,
    meta: {
      roles: [RolesEnum.Customer]
    }
  },

  {
    path: routeMap.giftCards.path,
    name: routeMap.giftCards.name,
    component: GiftCards,
    meta: {
      roles: [RolesEnum.Customer]
    }
  },
  {
    path: routeMap.userSettings.path,
    name: routeMap.userSettings.name,
    component: UserSettings,
    meta: {
      roles: [RolesEnum.Customer]
    }
  },
  // {
  //   path: routeMap.rotatingSubscriptions.path,
  //   name: routeMap.rotatingSubscriptions.name,
  //   component: RotatingSubscriptions,
  //   meta: {
  //     roles: [RolesEnum.Customer]
  //   }
  // }
]
