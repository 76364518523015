
<b-row v-if="loading" id="container" align-h="center" align-v="center">
  <b-col class="mx-auto">
    <b-img :src="loadingGif" class="w-100" fluid></b-img>
  </b-col>
</b-row>

<b-modal
    v-else
    v-model="active"
    size="lg"
    title-class="h3"
    body-class="product-order-modal-content-container"
    modal-class="product-order-modal-full-height"
    no-close-on-backdrop
    lazy
    @hidden="$emit('hidden', null)"
>
  <template #modal-title>
    <b-col class="p-0">
      <p class="h3">{{ model.name }}</p>
      <b-row v-if="!$store.getters.vendor" class="ml-0" align-v="center">
        <b-img
            v-if="vendor.logo_image"
            :alt="vendor.name"
            :src="vendor.logo_image"
            :title="vendor.name"
            rounded
            lazy
            class="vendor-logo"
            @error="vendor.logo_image = null"
        />
        <p class="h5 ml-2 mb-0">{{ vendor.name }}</p>
      </b-row>
    </b-col>
  </template>

  <ProductGrid
      key="products"
      :products="products"
      :loading="loading"
      :rotating-subscription="model"
      :meals-selected="mealsSelected"
      @toggle-favorite="toggleFavorite"
      @add-to-cart="addToOrder"
  ></ProductGrid>

  <template #modal-footer>
    <Schedule v-if="!!subscription" :days-of-week="daysOfWeek" :subscription="subscription" />

    <b-row
        class="justify-content-between w-100 m-0 macros-table"
        align-h="center"
        align-v="center"
    >
    </b-row>
    <b-row class="w-100 m-0 product-order-footer" align-v="center">
      <b-col class="px-0">
        <b-btn
            class="float-right footer-btn footer-btn-add"
            :disabled="!rotatingSubscriptionOrderValid"
            block
            @click="addToCart()"
        >
          <template v-if="rotatingSubscriptionOrderValid">
            <span class="small">{{ mealsSelected }} of {{ value.max_items }} (max) Meals Selected </span>
            <span class="mdi mdi-cart cart-icon"/>
            <span v-if="completePrice" class="product-order-footer__price">
              &ndash;
              <span>$</span>
              <number
                  :to="completePrice"
                  :duration="0.12"
                  :format="formatPriceToCompact"
              />
            </span>
          </template>
          <template v-else>
            Select {{ value.min_items }} Meals To Proceed
          </template>
        </b-btn>
      </b-col>
    </b-row>
  </template>
</b-modal>
