<template>
  <b-list-group-item v-if="productWrapper.product" class="p-0 border-0">
    <b-row class="mt-4 text-center" align-horiz="center" align-v="center">
      <b-col cols="12">
        <h4>{{ productWrapper.product.title }}</h4>
        <h5>
          ${{ productWrapper.unit_price
          }}<span v-if="productWrapper.smart_weight_purchase"
            >/{{ productWrapper.product.sold_by_weight_label }}</span
          >
          <span class="small text-muted mx-1">x</span
          >{{
            productWrapper.smart_weight_purchase
              ? productWrapper.weight
              : productWrapper.quantity
          }}<span v-if="productWrapper.smart_weight_purchase">{{
            soldByWeightLabel
          }}</span>
        </h5>
      </b-col>

      <b-col cols="auto">
        <b-img
          v-if="productImage"
          :alt="productWrapper.product.title"
          :src="productImage"
          :title="productWrapper.product.title"
          fluid
          lazy
          height="160"
          width="160"
          @error="productWrapper.product.image = null"
        />
        <div v-else class="image-placeholder product-placeholder">
          <div>{{ imagePlaceholder(productWrapper.product.title) }}</div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4" align-horiz="left" align-v="center">
      <b-col cols="12">
        <p class="mb-2" v-html="productWrapper.product.description"></p>
      </b-col>
    </b-row>
    <b-row class="my-3" align-horiz="left" align-v="center">
      <b-col cols="auto">
        <b-button
          v-if="showReviews"
          class="btn"
          @click="
            viewProductReviews = {
              uuid: productWrapper.product.uuid,
              title: productWrapper.product.title,
            }
          "
          >Review</b-button
        >
      </b-col>
      <b-col cols="8">
        <p v-if="showReviews" class="shrink-text m-0">
          Please note that you can only review a product once you have received
          it.
        </p>
      </b-col>
    </b-row>
    <b-row class="" align-horiz="left" align-v="center">
      <b-col cols="12">
        <order-product-modifiers
          v-if="
            region.governance.EnableFeatureModifiers &&
            productWrapper.modifier_items.length > 0
          "
          v-model="productWrapper.modifier_items"
          class="mt-3"
        />
      </b-col>
    </b-row>
    <product-reviews
      v-if="viewProductReviews && viewProductReviews.uuid"
      v-model="viewProductReviews"
    />
  </b-list-group-item>
</template>

<script>
import { smartWeightAbrOptions } from '~/enums/smartWeightOptions';
import ProductReviews from '@/shop/product-partials/ProductReviews.vue';
import OrderProductModifiers from '@/order-history/OrderProductModifiers.vue';
import { mapGetters } from 'vuex';
import { imagePlaceholder } from '^/utilities';

export default {
  name: 'OrderProduct',
  components: { OrderProductModifiers, 'product-reviews': ProductReviews },
  props: ['productWrapper', 'reviewable'],
  data() {
    return {
      viewProductReviews: { uuid: null, title: null },
    };
  },
  computed: {
    ...mapGetters(['region', 'vendor']),
    soldByWeightLabel() {
      return this.productWrapper.smart_weight_purchase
        ? smartWeightAbrOptions[this.productWrapper.ordered_weight].text
        : '';
    },
    showReviews() {
      return this.reviewable && this.vendor.settings.enable_reviews;
    },
    productImage() {
      return this.productWrapper.product.image;
    },
  },
  methods: {
    imagePlaceholder,
  },
};
</script>
<style lang="scss">
@import '../../styles/variables.module.scss';

ol {
  counter-reset: cupcake;
  padding-left: 16px;
  padding-top: 10px;
}

ol li {
  counter-increment: cupcake;
}

ol li:before {
  content: counters(cupcake, '.') '. ';
  color: var(--primary);
  font-weight: bold;
}

li {
  display: list-item;
}
</style>
<style lang="scss" scoped>
@import '../../styles/variables.module.scss';

@include buttons;

.product-placeholder {
  width: 160px;
  min-height: 160px;
}

.order-product-rating {
  max-width: 50%;
}

.order-product-image {
  width: 33.33333%;
  height: auto;
}

p {
  font-size: 0.9em;
}

h5 {
  font-size: 1em;
}

.shrink-text {
  font-size: 70%;
}

@media #{$belowMd} {
  .rating-text {
    font-size: 1.2em;
    margin-bottom: 0;
    margin-top: 0.25rem;
    padding-right: 0.75rem;
  }

  .rating-button {
    margin-left: 0.75rem;
    margin-top: 0.5rem;
  }
}

@media #{$belowLg} {
  .rating-button {
    margin-left: 0.75rem;
  }

  .rating-text {
    text-align: right !important;
    padding-right: 0.75rem;
  }
}
</style>
