<template>
  <div
    class="content-wrapper"
    :class="{
      tabContentWrapper: !changeTab,
    }"
  >
    <div
      class="wrapper"
      :class="{
        disabled:
          !loading &&
          (!nutritionRecommendation || !nutritionRecommendation.calories),
      }"
    >
      <div v-if="nutritionRecommendation && nutritionRecommendation.updated_by_vendor" class="my-0 override-warning">Recommended by {{ vendor.name }}</div>
      <h3 class="header">{{ user.name }}'s Macronutrients</h3>
      <div class="bg-circle">
        <div class="white-circle">
          <p>Total</p>
          <p class="calories">
            <skeleton v-if="loading || !nutritionRecommendation.calories" />
            <span v-else>{{ parseInt(nutritionRecommendation.calories) }}</span>
          </p>
          <p>calories</p>
        </div>
      </div>
      <div class="cards">
        <info-card
          title="Protein"
          :basic-info="`${
            nutritionRecommendation
              ? parseInt(nutritionRecommendation.protein)
              : ''
          }g`"
          :icon="SteakIcon"
          :loading="loading || !nutritionRecommendation.protein"
        />
        <info-card
          title="Carbs"
          :basic-info="`${
            nutritionRecommendation
              ? parseInt(nutritionRecommendation.carbs)
              : ''
          }g`"
          :icon="CarbsIcon"
          :loading="loading || !nutritionRecommendation.carbs"
        />
        <info-card
          title="Fat"
          :basic-info="`${
            nutritionRecommendation ? parseInt(nutritionRecommendation.fat) : ''
          }g`"
          :icon="FatIcon"
          :loading="loading || !nutritionRecommendation.fat"
        />
      </div>
    </div>
    <div
      v-if="
        !loading &&
        (!nutritionRecommendation || !nutritionRecommendation.calories)
      "
      class="dimmer"
    >
      <icon-base size="60">
        <lock-icon />
      </icon-base>
      <p>
        Complete the form to the right, and enter your current weight below, to
        unlock your macronutrients.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SteakIcon from '@/common/Icons/SteakIcon.vue';
import FatIcon from '@/common/Icons/FatIcon.vue';
import LockIcon from '@/common/Icons/LockIcon.vue';
import CarbsIcon from '@/common/Icons/CarbsIcon.vue';
import InfoCard from '@/common/InfoCard/InfoCard.vue';
import Skeleton from '@/common/Skeleton/Skeleton.vue';
import IconBase from '@/common/Icons/IconBase.vue';

import { axios } from '^/axios';

export default {
  props: {
    changeTab: { type: Function, required: true },
  },
  components: {
    InfoCard,
    Skeleton,
    LockIcon,
    IconBase,
  },
  computed: {
    ...mapGetters(['user', 'vendor']),
  },
  data() {
    return {
      nutritionRecommendation: null,
      SteakIcon,
      FatIcon,
      CarbsIcon,
      loading: true,
      disabled: true,
    };
  },
  created: async function () {
    await this.getNutritionRecommendation();
  },
  methods: {
    buttonClick() {
      this.changeTab(1);
    },

    async getNutritionRecommendation() {
      this.loading = true;

      try {
        const response = await axios.get(
          `/user/${this.user.uuid}/nutrition-recommendations`
        );

        this.nutritionRecommendation = response;
        if (!response.calories) {
          this.changeTab(1);
          this.disabled = true;
        }
      } catch (e) {
        await this.$store.dispatch('alertError', e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
}

.tabContentWrapper {
  padding: 40px;
}

.header {
  font-size: 20px;
  font-weight: bold;
  font-family: $main-font;
  text-align: center;
  padding-top: 15px;
}

.bg-circle {
  margin-top: 20px;
  max-width: 80%;
  width: 100%;
  aspect-ratio: 1 / 1;
  min-height: 200px;
  background: var(--circle-conic-gradient);
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;

  animation-duration: 0.5s;
  animation-name: animate-glow;
  animation-timing-function: ease;

  @keyframes animate-glow {
    0% {
      opacity: 0;
      filter: brightness(3) saturate(3);
      transform: scale(0.8, 0.8);
    }
    100% {
      opacity: 1;
      filter: brightness(1) saturate(1);
      transform: scale(1, 1);
    }
  }

  @media screen and (max-width: 991px) {
    height: 100%;
    max-width: none;
    width: auto;
    display: flex;
    flex-direction: column;
  }
}
.white-circle {
  height: 80%;
  min-height: 150px;
  aspect-ratio: 1 / 1;
  background-color: var(--white);
  border-radius: 200px;
  // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  box-shadow: $basic-shadow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calories {
  font-size: 36px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  min-width: 80px;
}

p {
  margin: 0;
  font-family: $main-font;
  font-weight: bold;
  color: $dark-gray;
  font-size: 20px;
}

.disabled {
  filter: blur(20px);
}

.dimmer {
  background-color: $dimmer;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  left: 0;
  & > p {
    color: black;
    margin-top: 20px;

    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;

    animation-duration: 0.5s;
    animation-name: animate-glow;
    animation-timing-function: ease;
  }
}

.cards {
  display: flex;
  gap: 20px;
  margin-top: 50px;
  width: 100%;
}

.override-warning {
  background-color: var(--primary);
  color: var(--white);
  padding: 0 8px;
  border-radius: 12px;
}
</style>
