
<b-container>
  <b-row class="justify-center pt-2">
    <div class="col-12 align-items-center d-flex">
      <router-link :to="`/cart`" class="position-absolute align-middle">
        <h2 class="px-0 mb-0">
          <b-icon-arrow-left-circle class="back-arrow"></b-icon-arrow-left-circle>
        </h2>
      </router-link>

      <h1 class="text-center px- mt-1 mb-0 w-100">
        {{ order.code ? 'Success' : 'Checkout' }}
        <span class="mdi mdi-cart cart-icon"></span>
      </h1>
    </div>
    <div class="col-12 align-items-center">
      <template v-if="!loading && region && vendor && !order.code">
        <h4 class="mt-1 mb-2 text-center px-1">{{ vendor.name }}</h4>
      </template>
    </div>
  </b-row>
  <hr class="mt-2 mb-4" />

  <b-row v-show="loading" key="loading" align-h="center">
    <b-img :src="loadingGif" class="mx-auto" fluid></b-img>
  </b-row>
  <div v-show="!loading">
    <b-row v-if="order && order.code !== null" key="success">
      <b-container class="checkout-container text-center">
        <template v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.scheduled">
          <p>
            Order <b>{{ order.code }}</b> has been <b>Scheduled</b>.
          </p>
          <template v-if="order.type === ORDER_TYPES.delivery">
            <p>We'll remind you by email before it departs for:</p>
            <p>
              <u>{{ order.address }}</u>
            </p>
          </template>
          <template v-else-if="order.type === ORDER_TYPES.pickup">
            <p> We'll remind you by email {{ vendorRegion.prep_time_minutes }} minutes before it's ready for
              <b>Pickup</b> on {{ moment(order.scheduled).format('MMM D, h:mma') }} at: </p>
            <p>
              <u>{{ vendorRegion.address.autocomplete }}</u>
            </p>
          </template>
        </template>
        <template v-else-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.subscribe">
          <p>Thanks for Subscribing, {{ contact.name }}!</p>
          <template v-if="order.type === ORDER_TYPES.delivery">
            <p> We'll email you when your <b>Deliveries</b> are on the way to: </p>
            <p>
              <u>{{ order.address }}</u>
            </p>
          </template>
          <template v-else-if="order.type === ORDER_TYPES.pickup">
            <p> We'll email you {{ vendorRegion.prep_time_minutes }} minutes before your orders will be ready for
              <b>Pickup</b> at: </p>
            <p>
              <u>{{ vendorRegion.address.autocomplete }}</u>
            </p>
          </template>
        </template>
        <template v-else>
          <p>
            Order <b>{{ order.code }}</b> has been <b>Received</b>.
          </p>
          <template v-if="order.type === ORDER_TYPES.delivery">
            <p>We'll email you when it's on it's way to:</p>
            <p>
              <u>{{ order.address }}</u>
            </p>
          </template>
          <template v-else-if="order.type === ORDER_TYPES.pickup">
            <p> It will be ready for <b>Pickup</b> on {{ moment(order.scheduled).format('MMM D, h:mma') }} at: </p>
            <p>
              <u>{{ vendorRegion.address.autocomplete }}</u>
            </p>
          </template>
        </template>

        <p>
          Questions? Get support
          <a target="_blank" :href="`mailto:${vendorRegion.operator_email}`">here</a>.
        </p>

        <b-row align-h="center" class="mt-5">
          <b-button class="btn-cta" to="/">Shop</b-button>
        </b-row>
      </b-container>
    </b-row>
    <b-row v-else>
      <b-container class="checkout-container">
        <h1 class="mb-3 text-center text-md-left">When?</h1>
        <b-row v-if="vendorRegion.delivery_enabled || vendorRegion.enable_pickup" class="justify-space-around"
          :align-h="rotatingSubscription ? 'flex-start' : 'center'">
            <template v-if="!rotatingSubscription">
              <b-col cols="12" lg="6">
                <b-form-group
                  v-if="cartProducts && cartProducts.length && !cartProducts[0].max_weekly_frequency && !cartProducts[0].subscription"
                  label="Choose the Frequency">
                  <b-form-select v-model="order.deliveryType" class="d-inline" prepend-icon="mdi-clock"
                    :options="options"></b-form-select>
                </b-form-group>
                <span v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.now" class="hint">
                  {{ vendor.name }} needs
                  {{ moment.duration(vendorRegion.prep_time_minutes, 'minutes').humanize(false, 'm') }}
                  notice to prepare an order.
                </span>
                <p v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.scheduled" class="mt-3"> 🕘 We'll see you later!
                </p>
              </b-col>

              <b-col v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.scheduled" cols="12" lg="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group :label="orderType === ORDER_TYPES.delivery ? 'Delivery Date' : 'Pickup Date'">
                      <b-input-group>
                        <b-form-datepicker v-model="order.date" class="date-picker" :date-disabled-fn="dateDisabled"
                          :max="maxDate" :min="minDate" locale="en"
                          :placeholder="`Choose a ${orderType === ORDER_TYPES.delivery ? 'delivery date' : 'pickup date'}`" />
                        <b-input-group-prepend>
                          <b-button class="rounded-end" variant="info" @click="order.date = order.time = null">
                            <b-icon-x />
                          </b-button>
                        </b-input-group-prepend>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="orderType !== ORDER_TYPES.shipping">
                  <b-col md="12">
                    <b-form-group
                      :label="noSlotsOnDay ? `${vendor.name} isn't open that day!` : orderType === ORDER_TYPES.delivery ? 'Delivery Window' : 'Pickup Time'">
                      <b-input-group>
                        <b-form-select v-model="order.time" :disabled="!order.date || noSlotsOnDay"
                          :options="order.date ? intervalOptions[moment(order.date).format('dddd').toLowerCase()] : []" />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <template v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.subscribe">
              </template>
            </template>
            <b-col v-if="cartProducts[0].subscription" cols="12">
              <p class="mt-3">This is a subscription for:</p>
              <p v-for="item in productSubscription" :key="item.day">
                <span class="time">{{ item.day }}: </span>
                <span>{{ moment(item.time, 'hh:mm:ss').format('h:mm A') }}</span>
              </p>
            </b-col>

            <b-col v-else-if="!loading" cols="12" lg="6">
              <p class="mt-3">🕘 Set it and forget it. We've got you.</p>
              <b-row>
                <b-col cols="12">
                  <SubscriptionEditor v-model="order.subscription" :subscription="subscribedDays" :vendor="vendor"
                    :order="order" :delivery-schedule-intervals="deliveryScheduleIntervals"
                    :pickup-schedule-intervals="pickupScheduleIntervals"
                    :weekly-frequency="weeklyFrequency"
                    @updateSelectedDays="updateSelectedDays" />
                </b-col>
              </b-row>
            </b-col>
        </b-row>

        <h1 class="mt-4 mb-2 text-center text-md-left">Who?</h1>
        <b-form ref="form" class="contact-form" novalidate @submit.prevent="checkout">
          <b-form-row align-h="center">
            <b-col md="4">
              <b-form-group label="Name">
                <b-form-input v-model.trim="contact.name" :state="contact.name && contact.name.length > 1"
                  placeholder="" required type="text" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Email">
                <b-form-input v-model.trim="contact.email" disabled placeholder="" required type="email">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Phone">
                <b-form-input v-model.trim="contact.phone" v-mask="'+1(###)###-####'"
                  :state="contact.phone && contact.phone.length === 15" required type="text" />
                <div id="credit-card-anchor"></div>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>

        <div>
          <h1 id="credit-card-title" class="mt-4 mb-3 text-center text-md-left"> Billing </h1>
          <payment-methods v-model="order.payment_method_uuid" />
        </div>

        <h1 class="mt-5 mb-3 text-center text-md-left">Order Note</h1>
        <b-form-textarea id="textarea" v-model="order.note" style="border-radius: 5px !important" max-rows="6"
          maxlength="500" rows="3"></b-form-textarea>

        <template v-if="order.order_type === ORDER_TYPES.delivery">
          <h1 class="mt-5 mb-3 text-center text-md-left">Delivery Note</h1>
          <b-form-textarea id="textarea" v-model="order.delivery_note" style="border-radius: 5px !important"
            max-rows="6" maxlength="500" rows="3"></b-form-textarea>
        </template>

        <p class="my-5 text-center" v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.now">
          {{ vendor.name }} needs
          {{ moment.duration(vendorRegion.prep_time_minutes, 'minutes').humanize(false, 'm') }}
          notice to prepare an order.
        </p>

        <b-row align-h="center" class="my-5">
          <b-button :disabled="false" class="btn-cta" @click="checkout">
            <template v-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.scheduled">Schedule</template>
            <template v-else-if="order.deliveryType === DELIVERY_TYPE_OPTIONS.subscribe">Subscribe</template>
            <template v-else>Continue</template>
          </b-button>
        </b-row>
      </b-container>
    </b-row>
  </div>
</b-container>
