<template>
  <div class="product-card">
    <div
      class="product-img-wrapper"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div
        class="product-img-wrapper__additional-info"
        style="top: 15px !important"
      >
        <b-badge
          v-if="value.product.rating"
          variant="light"
          @click.stop="viewReviews($event, value)"
        >
          {{ value.product.rating }}
          <b-icon icon="star-fill" color="gold"></b-icon>
        </b-badge>

        <b-badge
          variant="light"
          v-if="value && value.product.default_calories > 0"
        >
          <span>{{ value.product.default_calories }} cal</span>
        </b-badge>
      </div>

      <div v-if="showAvailabilityText" class="product-card__cut-off">
        {{ availabilityText }}
      </div>

      <b-badge
        v-if="rotatingSubscription.use_product_prices || rotatingSubscription.pricing_model === 'PER_MEAL'"
        variant="dark"
        class="product-img-wrapper__price"
        style="top: 15px !important"
      >
        <Currency
          prepend="$"
          :currency="value.price"
          :secondary-font-size="'0.825em'"
        />
      </b-badge>

      <b-img
        v-if="value.product.image"
        class="product-img"
        :alt="value.product.title"
        :src="value.product.image"
        :title="value.product.title"
        fluid
        lazy
        @error="value.product.image = null"
      />

      <div v-else class="image-placeholder">
        <div>{{ imagePlaceholder(value.product.title) }}</div>
      </div>

      <div class="product-card__tags">
        <Tag v-for="tag in value.product.tags" :key="tag.uuid" v-b-popover.hover.top="tag.name" :value="tag" size="sm"
             :v-b-popover="tag.name"/>
      </div>
    </div>

    <div class="product-card__title truncate">
      {{ value.product.title }}
    </div>

    <div
      @mouseover="hover = true"
      @mouseleave="hover = false"
      style="margin-top: auto; display: flex"
    >
      <b-button
        :disabled="outOfStock || !canEdit"
        block
        class="product-card__submit rotating-card__submit"
        @click="$emit('add-to-subscription', value)"
      >
        <span>Select</span>

        <div v-if="value.product.quantity" class="badge badge-danger rotating-card__meals-count">
          <span class="rotating-card__meals-count_text">
            {{ value.product.quantity }}
          </span>
        </div>
      </b-button>

      <b-button
        v-b-popover.hover.top="
          isFavorite ? 'Remove Favorite' : 'Mark as Favorite'
        "
        :v-b-popover="isFavorite ? 'Remove Favorite' : 'Mark as Favorite'"
        @click="toggleFavorite"
        :disabled="outOfStock || !canEdit"
        block
        class="product-card__submit rotating-card__favorite"
      >
        <div class="product-favorite__tags">
          <b-icon
            :icon="isFavorite ? 'heart-fill' : 'heart'"
            style="color: #cd0205"
          />
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import Currency from '@/Currency.vue';
import FixedWeightControls from '@/shop/product-partials/controls/FixedWeightControls.vue';
import SmartWeightControls from '@/shop/product-partials/controls/SmartWeightControls.vue';
import { mapGetters } from 'vuex';
import Tag from '^/components/Tag.vue';
import {
  addProductQty,
  calculatePricePerByWeight,
  getAbrWeightLabel,
  getProductMacros,
  imagePlaceholder,
  removeFromCart,
  subtractProductQty,
} from '^/utilities';
import { configuration } from '~/configuration';
import { smartWeightOptions } from '~/enums/smartWeightOptions';
import frequencyIcon from '../../../utils/constants/frequencyIcon';
import moment from 'moment-timezone';

export default {
  name: 'Product',
  components: {
    SmartWeightControls,
    FixedWeightControls,
    Currency,
    Tag,
  },
  props: {
    value: { type: Object, default: null },
    viewReviews: { type: Function, required: true },
    remainingSlots: { type: Number, default: Infinity },
    rotatingSubscription: { type: Object, default: null },
    canEdit: {type: Boolean, default: true},
  },
  data() {
    return {
      hover: false,
      configuration,
      smartWeightOptions,
      frequencyIcon,
      maxQuantity: Infinity,
      quantity: 0,
    };
  },
  created() {
    this.value.product.orderedWeight = this.value.product.sold_by_weight;
    this.quantity = 0;
    this.maxQuantity = this.value.max_per_customer || Infinity;
  },
  methods: {
    getAbrWeightLabel,
    calculatePricePerByWeight,
    addProductQty,
    subtractProductQty,
    removeFromCart,
    getProductMacros,
    imagePlaceholder,
    formatDateTime(date) {
      return moment(date).format('D MMMM'); // Example: "11 March"
    },
    toggleFavorite() {
      this.$set(this.value, 'favorite', !this.value.favorite);
      this.$emit('toggle-favorite', this.value)
    },
  },
  computed: {
    availabilityText() {
      const { available_beginning_override, available_ending_override } =
        this.value;

      if (!available_beginning_override) return ''; // No available start time

      const formattedStart = this.formatDateTime(available_beginning_override);
      if (!available_ending_override) {
        return `Starting ${formattedStart}`;
      }

      const formattedEnd = this.formatDateTime(available_ending_override);
      return ` ${formattedStart} to ${formattedEnd}`;
    },
    showAvailabilityText() {
      return (
        this.value.available_beginning_override ||
        this.value.available_ending_override
      );
    },
    isRegular() {
      return (
        !this.value.product.smart_weight_enabled &&
        !this.outOfStock &&
        !this.isFixedWeight
      );
    },
    isSmartWeight() {
      return (
        this.value.product.smart_weight_enabled &&
        !this.outOfStock &&
        !this.isFixedWeight
      );
    },
    isFixedWeight() {
      return (
        this.value.product.fixed_weight_enabled &&
        !this.outOfStock &&
        this.value.product.fixed_weight_prices.length > 0
      );
    },
    outOfStock() {
      return this.region.governance.EnableFeatureInventory &&
        this.value.product.smart_weight_enabled
        ? this.value.product.weight <= 0
        : false;
      // this.value.product.quantity <= 0 Need to decide whether to keep it for this rotating subscriptions
    },
    isFavorite() {
      return this.value.favorite;
    },
    ...mapGetters(['region']),
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.product-placeholder {
  width: 100% !important;
  min-height: 170px !important;
  border-radius: 10px 10px 0 0 !important;

  &:hover {
    cursor: pointer;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-card {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  font-family: 'Red Hat Text', sans-serif;
  position: relative;
  width: 200px;
  height: 295px;

  &__cut-off {
    position: absolute;
    width: 180px;
    top: -5%;
    left: 50%;
    transform: translate(-50%, -5%);
    padding: 4px;
    border-radius: 4px;
    background-color: var(--primary);
    color: white;
    font-size: 0.85rem;
    line-height: 0.85rem;
    text-align: center;
    font-weight: bold;
  }

  &__title {
    font-size: 0.95rem;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;

    align-content: center;
    min-height: 50px;
    z-index: 100;
  }

  &__tags {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 8px;

    width: 100%;
    position: absolute;
    bottom: 4px;
    left: 0;
  }

  &__submit {
    border-radius: 0 0 10px 10px;
    background-color: black;
    border-color: black;

    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.75);
      border-color: rgba(0, 0, 0, 0.75);
    }
  }
}

.product-favorite__tags {
  overflow: hidden;
  height: 100%;
  width: min-content;
  display: flex;
  place-content: center;
  flex-wrap: wrap;
}

.product-img-wrapper {
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px 10px 0 0;
  position: relative;

  & img {
    width: 100%;
    border-radius: inherit;
  }

  .frequency {
    letter-spacing: 0.025em;
    padding-bottom: 4px;
    font-size: 0.85em;
    position: absolute;
    right: 5px;
    top: 5px;
    text-align: center;
  }

  &__additional-info {
    position: absolute;
    top: 10px;
    left: 10px;

    display: flex;
    flex-flow: column nowrap;
    gap: 4px;

    & > * {
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__price {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: black;
    font-weight: 500;
    font-size: 14px;
  }
}

.rotating-card__submit {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  place-items: center;
  gap: 4px;
  border-bottom-right-radius: 0;
}

.rotating-card__meals-count {
  display: flex;
  flex-wrap: wrap;
  place-content: center;
  place-items: center;
  letter-spacing: 2px;
}

.rotating-card__meals-count_text {
  position: relative;
  left: 1px;
}

.rotating-card__favorite {
  margin-top: 0 !important;
  width: min-content;
  border-bottom-left-radius: 0;
  border-left: 1px solid gray;
}
</style>
