<template>
  <b-row v-if="loading" id="container" align-h="center" align-v="center">
    <b-col class="mx-auto">
      <b-img :src="loadingGif" class="w-100" fluid></b-img>
    </b-col>
  </b-row>

  <b-modal
      v-else
      v-model="active"
      size="lg"
      title-class="h3"
      body-class="product-order-modal-content-container"
      modal-class="product-order-modal-full-height"
      no-close-on-backdrop
      lazy
      @hidden="$emit('hidden', null)"
  >
    <template #modal-title>
      <b-col class="p-0">
        <p class="h3">{{ model.name }}</p>
        <b-row v-if="!$store.getters.vendor" class="ml-0" align-v="center">
          <b-img
              v-if="vendor.logo_image"
              :alt="vendor.name"
              :src="vendor.logo_image"
              :title="vendor.name"
              rounded
              lazy
              class="vendor-logo"
              @error="vendor.logo_image = null"
          />
          <p class="h5 ml-2 mb-0">{{ vendor.name }}</p>
        </b-row>
      </b-col>
    </template>

    <ProductGrid
        key="products"
        :products="products"
        :loading="loading"
        :rotating-subscription="model"
        :meals-selected="mealsSelected"
        @toggle-favorite="toggleFavorite"
        @add-to-cart="addToOrder"
    ></ProductGrid>

    <template #modal-footer>
      <Schedule v-if="!!subscription" :days-of-week="daysOfWeek" :subscription="subscription" />

      <b-row
          class="justify-content-between w-100 m-0 macros-table"
          align-h="center"
          align-v="center"
      >
      </b-row>
      <b-row class="w-100 m-0 product-order-footer" align-v="center">
        <b-col class="px-0">
          <b-btn
              class="float-right footer-btn footer-btn-add"
              :disabled="!rotatingSubscriptionOrderValid"
              block
              @click="addToCart()"
          >
            <template v-if="rotatingSubscriptionOrderValid">
              <span class="small">{{ mealsSelected }} of {{ value.max_items }} (max) Meals Selected </span>
              <span class="mdi mdi-cart cart-icon"/>
              <span v-if="completePrice" class="product-order-footer__price">
                &ndash;
                <span>$</span>
                <number
                    :to="completePrice"
                    :duration="0.12"
                    :format="formatPriceToCompact"
                />
              </span>
            </template>
            <template v-else>
              Select {{ value.min_items }} Meals To Proceed
            </template>
          </b-btn>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { formatPriceToCompact } from '%/formatPrice';
import loadingGif from '~/../assets/loading.gif'
import ProductModifiers from '@/shop/product-partials/ProductModifiers.vue';
import ProductReviews from '@/shop/product-partials/ProductReviews.vue';
import {cloneDeep} from 'lodash';
import {mapGetters} from 'vuex';
import Tag from '^/components/Tag.vue';
import {axios} from '^/axios';
import {configuration} from '~/configuration';

import {imagePlaceholder, removeCart} from '^/utilities';
import ProductMacrosTable from '@/Macronutrients/Macros/ProductMacrosTable.vue';
import ProductGrid from "@/shop/rotating-subscription-partials/ProductGrid.vue";
import Schedule from "@/shop/rotating-subscription-partials/Schedule.vue";

export default {
  name: 'RotatingSubscriptionOrderModal',
  props: ['value', 'vendor_uuid', 'currentlySelectedProducts'],
  data() {
    return {
      loading: false,
      active: false,
      model: {
        name: String(),
        rotatingSubscription: true,
        quantityOrdered: 1,
        image_url: String(),
        cutoff_day_override: Number(),
        cutoff_time_override: Date,
        id: Number(),
        is_customer_selected: Boolean(),
        max_items: Number(),
        min_items: Number(),
        price_override: Boolean(),
        pricing_model: String(),
        region_id: Number(),
        subscription_id: Number(),
        use_product_prices: Boolean(),
        uuid: String(),
        vendor_id: Number(),
        subtotal: Number(),
      },
      products: [],
      selectedProducts: {},
      subscription: {},
      daysOfWeek: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      cloneDeep,
      configuration,
      loadingGif
    };
  },
  watch: {
    value(val) {
      this.active = val != null;
    },

    active(val) {
      if (val && this.currentlySelectedProducts && this.currentlySelectedProducts.length > 0) {
        for (const p of this.currentlySelectedProducts) {
          this.selectedProducts[p.product_uuid] = p
        }
      }
    }
  },
  async created() {
    this.loading = true;
    this.model = cloneDeep(this.value);
    if (!this.model.quantityOrdered) {
      this.model.quantityOrdered = 1;
    }
    this.products = await this.getProducts();
    this.subscription = (await this.getSubscription());
    this.$forceUpdate();
    this.loading = false;
    this.active = true;
  },
  methods: {
    formatPriceToCompact,
    imagePlaceholder,
    removeCart,
    async getProducts() {
      const products = await axios.get(`/rotating-subscription/${this.model.uuid}/products`);
      return products.map((product) => {
        const selectedProduct = this.currentlySelectedProducts?.find(p => p.id === product.id);
        product.price = selectedProduct?.price || parseFloat(product.product.price_with_defaults).toFixed(2);
        product.product.quantity = selectedProduct?.quantity || 0;
        product.product.modifiers = selectedProduct?.modifiers || [];
        product.product.ingredients = selectedProduct?.ingredients || [];
        product.product.recipes = selectedProduct?.recipes || [];
        product.favorite = selectedProduct?.favorite || false;

        return product;
      });
    },
    async getSubscription() {
      return await axios.get(`/rotating-subscription/${this.model.uuid}/subscription`);
    },
    formatToPrice(value) {
      return Math.max(value).toFixed(2);
    },
    clearCart() {
      this.removeCart();
      this.$bvToast.hide();
    },
    async addToOrder(product) {
      if (!product.quantity) {
        this.$delete(this.selectedProducts, product.product_uuid); // Remove product when quantity is 0
      } else {
        this.$delete(this.selectedProducts, product.product_uuid); // Remove product when quantity is 0
        this.$set(this.selectedProducts, product.product_uuid, { ...product, quantity: product.quantity });
      }
    },
    async addToCart() {
      if (this.currentlySelectedProducts && this.currentlySelectedProducts.length > 0) {
        this.$store.commit(
            'updateRotatingSubscription',
            {
              ...this.model,
              products: Object.values(this.selectedProducts),
              subscription: true,
              subscriptionType: 'rotating-subscription'
            }
        );

        this.$emit('hidden', null);
        this.active = false;
      }

      const isSubscriptionItemExistInTheCart = this.cartProducts.find(
          (product) => !!product.subscription
      );

      const isCurrentItemForSubscription = true;

      const isUnableToAddToCard =
          isSubscriptionItemExistInTheCart ||
          (this.cartProducts.length > 0 && isCurrentItemForSubscription);

      if (!isUnableToAddToCard) {
        this.$store.commit(
            'addProductToCart',
            {
              ...this.model,
              products: Object.values(this.selectedProducts),
              subscription: true,
              subscriptionType: 'rotating-subscription'
            }
        );
        this.$emit('hidden', null);
        this.active = false;
      }

      if (isUnableToAddToCard) {
        let title =
            'You can not add subscription item to cart, if there are already exist something. Subscription items can be handled alone';
        if (isSubscriptionItemExistInTheCart) {
          title = `You can not add this item to cart because you already have subscription item in your cart. Subscription items can be handled alone`;
        }

        this.toastButtonMsg = 'Remove Them!';
        const h = this.$createElement;
        const button = h(
            'button',
            {
              class: ['btn', 'btn-primary', 'clearCart'],
              on: {click: this.clearCart}, // Attach a click event listener
            },
            'Remove Them!'
        );
        this.$bvToast.toast([title, button], {
          title: `The Item was not added to the cart`,
          variant: 'danger',
          autoHideDelay: 10000,
        });
      }
    },
    toggleFavorite(product) {
      let index = this.products.findIndex(p => p.id === product.id);
      this.products[index].favorite = product.favorite;
      this.selectedProducts[product.product_uuid].favorite = product.favorite;
    }
  },
  computed: {
    ...mapGetters(['region', 'cartProducts', 'vendor']),
    completePrice() {
      this.model.subtotal = 0.0;

      if (this.model.use_product_prices) {
        this.model.subtotal = Object.values(this.selectedProducts).reduce((totalPrice, product) => {
          return totalPrice + (parseFloat(product.price));
        }, 0.0);
      } else if (this.model.pricing_model === 'PER_MEAL') {
        this.model.subtotal = Object.values(this.selectedProducts).reduce((totalPrice, product) => {
          return totalPrice + (parseFloat(product.price));
        }, 0.0);
      } else if (this.model.pricing_model === 'PER_TIMESLOT') {
        this.model.subtotal = this.daysOfWeek.reduce((totalPrice, day) => {
          return totalPrice + !!this.subscription[day] ? this.model.price_override : 0
        }, 0.0);
      }

      return this.formatToPrice(this.model.subtotal);
    },
    rotatingSubscriptionOrderValid() {
      const mealsSelected = this.mealsSelected;
      return mealsSelected >= this.model.min_items && mealsSelected <= this.model.max_items;
    },
    mealsSelected() {
      return Object.values(this.selectedProducts).reduce((acc, item) => {
        return acc + (item.quantity || 0);
      }, 0);
    }
  },
  components: {
    Schedule,
    ProductGrid,
    ProductModifiers,
    ProductReviews,
    Tag,
    ProductMacrosTable
  },
};
</script>

<style lang="scss">
@import '../../../styles/variables.module.scss';

.order-product-description {
  word-wrap: break-word;
  overflow-wrap: break-word;

  ol {
    counter-reset: unset;
  }

  ol li {
    counter-increment: unset;
  }

  ol li:before {
    content: unset;
    color: var(--primary);
    font-weight: bold;
  }

  li {
    display: list-item;
  }
}

.modal-header .modal-title {
  font-size: 2em !important;
}

.modal-header .close {
  font-size: 2.75em !important;
}
</style>
<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

@include buttons;

.text-reviews,
.ingredients-label {
  font-family: $staatliches-font;
}

.text-reviews {
  color: black;
}

.product-image {
  max-width: 170px;
  border: var(--primary) 1px solid;
  border-radius: 5px;
  background-color: var(--primary);
}

.title-class {
  font-size: 1.75em !important;
}

.dollar {
  color: grey;
}

.right-product-column {
  p {
    color: grey;
  }
}

.badge {
  font-size: 14px;
}

.macros-table {
  &-header {
    color: white;
    background-color: var(--primary) !important;
  }
}

.footer-btn {
  border-radius: 0 !important;
  text-shadow: none !important;
  font-size: 1.3em;
  letter-spacing: 0.09em;
  padding: 5px !important;
  max-height: 60px !important;
  min-height: 60px !important;
  overflow: hidden;

  &:active {
    background-color: var(--primary);
  }

  &:disabled {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
  }

  &:hover {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
  }
}

.footer-btn-add {
  max-width: unset;
  background-color: black;
  border-color: black;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.btn-circle {
  padding: 0;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  line-height: 25px;
}

::v-deep .modal-body {
  padding-bottom: 0;
}

::v-deep .modal-footer {
  padding: 0;
}

::v-deep .modal-content {
  border: 0;
}

::v-deep .b-rating-value {
  padding-right: 0;
}

@media #{$belowMd} {
  ::v-deep .modal-dialog {
    margin: 0 auto;
    height: 100%;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;

    .modal-content {
      max-height: unset;
    }
  }
}

.modal-footer {
  background-color: black !important;
}

.vendor-logo {
  max-width: 40px;
  max-height: 40px;
}

.clearCart {
  margin: 10px 0px;
  font-size: 0.8rem;
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: sans-serif;
  background: #721c24;
  border: #721c24 2px solid;
}

.error-text {
  font-weight: bold;
  color: red;
}

.max-text {
  font-weight: bold;
}

.product-order-footer {
  &__price {
    font-size: 16px;
    font-weight: 400;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}
</style>

<style>
.product-order-modal-full-height {
  height: 100svh;
  margin: 0;
  padding: 0;
}

.product-order-modal-full-height .modal-dialog {
  height: 100%;
  margin: 0 auto;
}

.product-order-modal-full-height .modal-content {
  height: 100%;
  margin: 0 auto;
}

.product-order-modal-content-container {
  overflow-y: scroll;
}

#container {
  max-width: 1000px;
  width: 100%;
  background-color: white;
  height: 100%;
  opacity: .5;
  top: 0;
  position: fixed;
}

.no-scroll {
  overflow-x: hidden;
}

.delivery-schedule-col:not(:first-child) {
  border-left: 1px solid var(--primary);
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>

