
<div
  class="content-wrapper"
  :class="{
    tabContentWrapper: !changeTab,
  }"
>
  <div
    class="wrapper"
    :class="{
      disabled:
        !loading &&
        (!nutritionRecommendation || !nutritionRecommendation.calories),
    }"
  >
    <div v-if="nutritionRecommendation && nutritionRecommendation.updated_by_vendor" class="my-0 override-warning">Recommended by {{ vendor.name }}</div>
    <h3 class="header">{{ user.name }}'s Macronutrients</h3>
    <div class="bg-circle">
      <div class="white-circle">
        <p>Total</p>
        <p class="calories">
          <skeleton v-if="loading || !nutritionRecommendation.calories" />
          <span v-else>{{ parseInt(nutritionRecommendation.calories) }}</span>
        </p>
        <p>calories</p>
      </div>
    </div>
    <div class="cards">
      <info-card
        title="Protein"
        :basic-info="`${
          nutritionRecommendation
            ? parseInt(nutritionRecommendation.protein)
            : ''
        }g`"
        :icon="SteakIcon"
        :loading="loading || !nutritionRecommendation.protein"
      />
      <info-card
        title="Carbs"
        :basic-info="`${
          nutritionRecommendation
            ? parseInt(nutritionRecommendation.carbs)
            : ''
        }g`"
        :icon="CarbsIcon"
        :loading="loading || !nutritionRecommendation.carbs"
      />
      <info-card
        title="Fat"
        :basic-info="`${
          nutritionRecommendation ? parseInt(nutritionRecommendation.fat) : ''
        }g`"
        :icon="FatIcon"
        :loading="loading || !nutritionRecommendation.fat"
      />
    </div>
  </div>
  <div
    v-if="
      !loading &&
      (!nutritionRecommendation || !nutritionRecommendation.calories)
    "
    class="dimmer"
  >
    <icon-base size="60">
      <lock-icon />
    </icon-base>
    <p>
      Complete the form to the right, and enter your current weight below, to
      unlock your macronutrients.
    </p>
  </div>
</div>
