<template>
  <b-row align-h="center" justify="center">
    <b-row
        align-h="center"
        justify="center"
        v-if="products.length > 0"
        class="text-center products-list mx-auto"
    >
      <product
          v-for="(product, x) in products"
          :key="x"
          v-model="products[x]"
          :remaining-slots="remainingSlots"
          :rotating-subscription="rotatingSubscription"
          :view-reviews="viewReviews"
          :can-edit="canEdit"
          @toggle-favorite="toggleFavorite"
          @add-to-subscription="triggerOrderModal"
      />
    </b-row>

    <b-row v-else-if="loading" key="loading" class="text-center no-products pt-3">
      <b-col xs12>
        <h1>Loading</h1>
        <p>Please wait.</p>
      </b-col>
    </b-row>

    <product-info v-if="viewProductInfo" v-model="viewProductInfo" :view-reviews="viewReviews"/>
    <product-order-modal v-if="orderProduct" v-model="orderProduct" :vendor_uuid="vendor.uuid"
                         :nutrition-recommendation="nutritionRecommendation" @hidden="orderProduct = null"
                         :meals-selected="mealsSelected" :rotating-subscription="rotatingSubscription"
                         @add-product-to-subscription="addProductToSubscription"/>
    <product-reviews v-if="viewProductReviews && viewProductReviews.uuid" v-model="viewProductReviews"/>
  </b-row>
</template>

<script>
import {configuration} from '~/configuration'
import Product from '@/shop/rotating-subscription-partials/Product.vue'
import {mapGetters} from 'vuex'
import ProductOrderModal from "@/shop/rotating-subscription-partials/ProductOrderModal.vue";
import ProductInfo from "@/shop/product-partials/ProductInfo.vue";
import ProductReviews from "@/shop/product-partials/ProductReviews.vue";
import {axios} from "^/axios";

export default {
  name: "ProductGrid",
  components: {ProductReviews, ProductInfo, ProductOrderModal, Product},
  props: {
    products: {type: Array, required: true},
    rotatingSubscription: {
      type: Object,
      required: true,
      default: () => ({})
    },
    mealsSelected: {
      type: Number,
      required: true,
      default: 0
    },
    loading: {type: Boolean, default: false},
    canEdit: {type: Boolean, default: true}
  },
  data() {
    return {
      viewProductInfo: null,
      orderProduct: null,
      viewProductReviews: {id: null, title: null},
      subscriptionProducts: {},  // Ensure it's an object
      model: {},
      configuration,
      nutritionRecommendation: {}
    };
  },
  async created() {
    if (this.user) this.nutritionRecommendation = await this.getNutritionRecommendation()
  },
  watch: {
    products: {
      immediate: true,
      deep: true,
      handler(newProducts) {
        newProducts.forEach(product => {
          if (!this.subscriptionProducts[product.product_uuid]) {
            this.$set(
                this.subscriptionProducts, product.product_uuid,
                {
                  ...product, quantity: product.quantity || 0,
                  product: {...product.product, price: product.product.price_with_defaults}
                });
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'vendor']),
    remainingSlots() {
      return this.rotatingSubscription.max_items - Object.values(this.subscriptionProducts).reduce((acc, product) => {
        return acc + (product.quantity || 0);
      }, 0);
    }
  },
  methods: {
    async setProductQuantity(product, quantity) {
      if (this.subscriptionProducts[product.product_uuid]) {
        this.$set(this.subscriptionProducts[product.product_uuid], 'quantity', quantity);
        this.$emit('add-to-cart', this.subscriptionProducts[product.product_uuid]);
      }
    },
    viewReviews(e, product) {
      this.viewProductReviews = product
    },
    triggerOrderModal(product) {
      if (!product.quantityOrdered) product.quantityOrdered = 1
      this.orderProduct = product
    },
    async getNutritionRecommendation() {
      try {
        const response = await axios.get(`/user/${this.user.uuid}/nutrition-recommendations`)
        if (!response.calories) return
        return response
      } catch (e) {
        await this.$store.dispatch('alertError', e.message)
      }
    },
    addProductToSubscription(product) {
      this.$set(this.subscriptionProducts, product.product_uuid, product);
      this.$emit('add-to-cart', this.subscriptionProducts[product.product_uuid]);
    },
    toggleFavorite(product) {
      this.$emit('toggle-favorite', product)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 600px !important;
  margin: auto 0;
}

.products-list {
  display: flex;
  flex-flow: row wrap;
  column-gap: 24px;
  row-gap: 34px;
  margin: 24px 0 24px 0;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.no-products {
  p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
}
</style>