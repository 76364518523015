<template>
  <div class="rotating-subscription-card">
    <div class="rotating-subscription-img-wrapper" @mouseover="hover = true" @mouseleave="hover = false"
         @click="$emit('add-to-cart', value)">
      <div class="rotating-subscription-img-wrapper__additional-info"
           :style="(cutOffString.length > 0 ? 'top:15px !important;':'')">
      </div>

      <div v-if="value.cutoff_day_override !== null" class="rotating-subscription-card__cut-off">
        Order by {{ cutOffString }}
      </div>

      <b-badge v-if="!value.use_product_prices"  variant="dark" class="rotating-subscription-img-wrapper__price"
               :style="(cutOffString.length > 0 ? 'top:15px !important;':'')">
        <Currency
            prepend="$"
            :currency="value.price_override"
            :append="pricingModel"
            :secondary-font-size="'0.825em'"
        />
      </b-badge>

      <b-img v-if="value.image_url" class="rotating-subscription-img" :alt="value.name" :src="value.image_url"
             :title="value.name" fluid lazy @error="value.image_url = null"/>

      <div v-else class="image-placeholder">
        <div>{{ imagePlaceholder(value.name) }}</div>
      </div>

    </div>

    <div class="rotating-subscription-card__title truncate">
      {{ value.name }}
    </div>


    <div v-if="isRegular" @mouseover="hover = true" @mouseleave="hover = false" style="margin-top: auto">
      <b-button :disabled="outOfStock" block class="rotating-subscription-card__submit"
                @click="$emit('create-subscription', value)">
        Order
      </b-button>
    </div>

    <b-row align-h="center"
           v-if="region.governance.EnableFeatureInventory || (isSmartWeight && !region.governance.EnableFeatureSmartWeight)">
      <b-form-invalid-feedback v-if="region.governance.EnableFeatureInventory" id="inputLiveFeedback"
                               class="rotating-subscription-quantity-feedback mb-0"> Enter a number
      </b-form-invalid-feedback>
      <b-form-text v-else id="inputLiveHelp" class="rotating-subscription-quantity-feedback">
        SmartWeight&trade; products aren't available in the
        {{ region.name }} region yet. Check back soon.
      </b-form-text>
    </b-row>
  </div>
</template>

<script>
import Currency from '@/Currency.vue'
import FixedWeightControls from '@/shop/product-partials/controls/FixedWeightControls.vue'
import SmartWeightControls from '@/shop/product-partials/controls/SmartWeightControls.vue'
import {mapGetters} from 'vuex'
import Tag from '^/components/Tag.vue'
import {
  addProductQty,
  addToCart,
  calculatePricePerByWeight,
  getAbrWeightLabel,
  getProductMacros,
  imagePlaceholder,
  removeFromCart,
  subtractProductQty
} from '^/utilities'
import {configuration} from '~/configuration'
import {smartWeightOptions} from '~/enums/smartWeightOptions'
import frequencyIcon from '../../../utils/constants/frequencyIcon'
import moment from 'moment-timezone';

export default {
  name: 'RotatingSubscription',
  components: {
    SmartWeightControls,
    FixedWeightControls,
    Currency,
    Tag
  },
  props: {
    value: {type: Object, default: null},
  },
  data() {
    return {
      hover: false,
      configuration,
      smartWeightOptions,
      frequencyIcon
    }
  },
  created() {
    this.value.orderedWeight = this.value.sold_by_weight
  },
  methods: {
    getAbrWeightLabel,
    calculatePricePerByWeight,
    addToCart,
    addProductQty,
    subtractProductQty,
    removeFromCart,
    getProductMacros,
    imagePlaceholder
  },
  computed: {
    cutOffString() {
      if (!this.value.cutoff_time_override) return ''

      this.soonestCutOff = moment(this.value.cutoff_time_override, 'HH:mm:ss').set("week", moment().week()).isoWeekday(this.value.cutoff_day_override);
      const soonestCutOffFormat = moment(this.soonestCutOff, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

      const cutOffDate = moment.tz(soonestCutOffFormat, 'YYYY-MM-DD HH:mm:ss', 'UTC');

      return cutOffDate.format('h:mm a dddd');
    },
    isRegular() {
      return !this.value.smart_weight_enabled && !this.outOfStock && !this.isFixedWeight
    },
    isSmartWeight() {
      return this.value.smart_weight_enabled && !this.outOfStock && !this.isFixedWeight
    },
    isFixedWeight() {
      return this.value.fixed_weight_enabled && !this.outOfStock && this.value.fixed_weight_prices.length > 0
    },
    outOfStock() {
      return this.region.governance.EnableFeatureInventory && this.value.smart_weight_enabled ? this.value.weight <= 0 : this.value.quantity <= 0
    },
    pricingModel() {
      return {
        PER_TIMESLOT: "",
        PER_MEAL: " / Meal"
      }[this.value.pricing_model];
    },
    ...mapGetters(['region'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables.module.scss';

.rotating-subscription-placeholder {
  width: 100% !important;
  min-height: 170px !important;
  border-radius: 10px 10px 0 0 !important;

  &:hover {
    cursor: pointer;
  }
}

.rotating-subscription-card__title {
  display: flex;
  align-content: center;
  min-height: 40px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rotating-subscription-card {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  font-family: 'Red Hat Text', sans-serif;
  position: relative;
  width: 200px;
  height: 295px;

  &__cut-off {
    position: absolute;
    width: 180px;
    top: -5%;
    left: 50%;
    transform: translate(-50%, -5%);
    padding: 4px;
    border-radius: 4px;
    background-color: var(--primary);
    color: white;
    font-size: 0.85rem;
    line-height: 0.85rem;
    text-align: center;
    font-weight: bold;
  }

  &__title {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
    z-index: 10;
  }

  &__tags {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 8px;

    width: 100%;
    position: absolute;
    bottom: 4px;
    left: 0;
  }

  &__submit {
    border-radius: 0 0 10px 10px;
    background-color: black;
    border-color: black;

    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.75);
      border-color: rgba(0, 0, 0, 0.75);
    }
  }
}

.rotating-subscription-img-wrapper {
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px 10px 0 0;
  position: relative;

  & img {
    width: 100%;
    border-radius: inherit;
  }

  .frequency {
    letter-spacing: 0.025em;
    padding-bottom: 4px;
    font-size: 0.85em;
    position: absolute;
    right: 5px;
    top: 5px;
    text-align: center;
  }

  &__additional-info {
    position: absolute;
    top: 10px;
    left: 10px;

    display: flex;
    flex-flow: column nowrap;
    gap: 4px;

    & > * {
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__price {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: black;
    font-weight: 500;
    font-size: 14px;
  }
}
</style>
